import React, { useEffect } from 'react'
import {useNavigate} from "react-router-dom"
import {useSelector} from "react-redux"
import HeroSection from '../../components/HomeBox/HeroSection/HeroSection'
import DemoSection from '../../components/HomeBox/DemoSection/DemoSection'
import FeaturesSection from '../../components/HomeBox/FeaturesSection/FeaturesSection'
import "./home.scss"

/**
 * Home component renders the landing page of the application.
 * If the user is authenticated, it automatically redirects to the dashboard.
 * @component
 */

function Home() {
  const navigate = useNavigate()

  // Select user state from Redux store
  const {user} = useSelector((state) => state.auth)

   // useEffect hook to redirect authenticated users to the dashboard
  useEffect(() => {
    if(user) {
      navigate("/dashboard")
    }
  }, [user, navigate])

  return (  
    <div className='home'>
      <div className="home-box-top home-box1">
        <HeroSection/>
      </div>
      <div className="home-box home-box2">
        <FeaturesSection/>
      </div>
      <div className="home-box home-box3">
        <DemoSection/>
      </div>        
    </div>  
  )
}

export default Home
