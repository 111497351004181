import { useEffect, useRef } from "react";
import { FaSackDollar } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { RiUserAddFill } from "react-icons/ri";
import { BsFillPieChartFill } from "react-icons/bs";
import { IoSettingsSharp   } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import "./featuresSection.scss"

/**
 * Component for displaying features section with icons.
 * @component FeaturesSection
 * @returns {JSX.Element} FeaturesSection component JSX
 */

function FeaturesSection(){
  // Create a ref to store references to the feature boxes
  const featuresRef = useRef([]);

  // UseEffect hook to observe when feature boxes enter the viewport
  useEffect(() => {
    // Options for IntersectionObserver
    const options = {
      threshold: 0.5, 
    };

    // IntersectionObserver callback
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    }, options);

    // Observe each feature box
    featuresRef.current.forEach(feature => {
      observer.observe(feature);
    });

    // Cleanup function
    return () => {
      observer.disconnect(); // Disconnect observer when component unmounts
    };
  }, []);

    return(
        <div className="home-features">
            <h1>FEATURES</h1>
            <div className='features-section'>
                <div className="features-box features-box1" ref={el => (featuresRef.current[0] = el)}>
                    <p className="feature-content-icon"><RiUserAddFill/></p> 
                    <p className="feature-content">Manage your players and penalties</p>             
                </div>
                <div className="features-box features-box2" ref={el => (featuresRef.current[1] = el)}>
                    <p className="feature-content-icon"><FaSackDollar/></p>
                    <p className="feature-content">Track and assign fees</p>
                </div>    
                <div className="features-box features-box3" ref={el => (featuresRef.current[2] = el)}>
                    <p className="feature-content-icon"><FaEdit/></p>
                    <p className="feature-content">Edit your players and penalties</p>
                </div>
                <div className="features-box features-box4" ref={el => (featuresRef.current[3] = el)}>
                    <p className="feature-content-icon"><BsFillPieChartFill /></p>
                    <p className="feature-content">Get insights about your records</p>
                </div>
                <div className="features-box features-box5" ref={el => (featuresRef.current[4] = el)}>
                    <p className="feature-content-icon"><IoSettingsSharp /></p>
                    <p className="feature-content">Manage monthly memberships</p>
                </div>
                <div className="features-box features-box6" ref={el => (featuresRef.current[5] = el)}>
                    <p className="feature-content-icon"><FaDownload /></p>
                    <p className="feature-content">Export and download your records</p>
                </div>
            </div>
        </div>
    );
}

export default FeaturesSection;