import React, { useState } from 'react'
import { FaSave } from "react-icons/fa";
import {useDispatch} from "react-redux"
import { updatePenalty } from '../../features/penalties/penaltySlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

/**
 * Component for editing penalty information.
 * @module PenaltyFormEdit
 * @param {Object} penaltyEditData - Data of the penalty being edited.
 * @returns {JSX.Element} PenaltyFormEdit component.
 */

function PenaltyFormEdit({penaltyEditData}) {
  const [penaltyData, setPenaltyData] = useState({
    penaltyId: penaltyEditData._id,
    penaltyName: penaltyEditData.penaltyName,
    penaltyFee: penaltyEditData.penaltyFee,
  })

  const {/*penaltyId,*/ penaltyName, penaltyFee} = penaltyData

  const dispatch = useDispatch()

  /**
   * Handles form submission for updating penalty information.
   * @function
   * @name onSubmit
   * @param {Event} e - The form submission event.
   * @returns {void}
   */
  const onSubmit = (e) => {
    e.preventDefault()

    if(!penaltyName || !penaltyFee){
      toast.info("Penalty Infos cannot be empty. Enter all Penalty Infos.")
    } else {
      dispatch(updatePenalty(penaltyData));
      toast.success("Penalty updated!")
    }
  }

  /**
   * Handles input change for penalty information fields.
   * @function
   * @name onChange
   * @param {Event} e - The input change event.
   * @returns {void}
   */
  const onChange = (e) => {
    setPenaltyData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <section className='form'>
      <form onSubmit={onSubmit}>        
        <div className="form-group">
          <label htmlFor='penaltyName'>Name</label>
          <input type="text" name="penaltyName" id="penaltyName" value={penaltyName} onChange={onChange} placeholder="Penalty Name" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='penaltyFee'>Fee</label>
          <input type="number" name="penaltyFee" id="penaltyFee" value={penaltyFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$"/>
        </div>
        <div className="form-group">
          <button className="btn btn-block-modal" type='submit'><FaSave /> Save Changes</button>
        </div>
      </form>
    </section>
  )
}

PenaltyFormEdit.propTypes = {
  /**
   * Data of the penalty being edited.
   */
  penaltyEditData: PropTypes.object.isRequired,
  /**
   * Function to handle form submission for updating penalty information.
   */
  onSubmit: PropTypes.func,
  /**
   * Function to handle input change for penalty information fields.
   */
  onChange: PropTypes.func,
};

export default PenaltyFormEdit
