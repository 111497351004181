import { useNavigate} from "react-router-dom"
import {FaSignInAlt} from "react-icons/fa"
import { RiUserAddFill } from "react-icons/ri";
import "./heroSection.scss"

/**
 * Component for displaying hero section with login and register buttons.
 * @component HeroSection
 * @returns {JSX.Element} HeroSection component JSX
 */

function HeroSection(){
    const navigate = useNavigate()

    return(
        <>
            <div className='hero-section'>
                <div className="hero-box hero-box1"> 
                    <div className="hero-text-container">
                        <img src="/img/logo512.webp" width="75" height="75" alt="PenPro Logo"/>
                        <h1>PenPro</h1>
                        <h2>Simplify. Manage. Succeed.</h2>
                        <p>Digital Penalty Management System.</p>
                    </div>                  
                    <div className="button-container">
                        <button className="hero-btn" onClick={() => navigate("/login")}>
                            <FaSignInAlt/> Login
                        </button>
                        <button className="hero-btn" onClick={() => navigate("/register")}>
                            <RiUserAddFill/> Register
                        </button>
                    </div>                
                </div>
                <div className="hero-box hero-box2">
                    <img className="hero-img" src="img/hero_box.png" alt="Setup Hero" />                
                </div>       
            </div>           
        </>
    );
}

export default HeroSection;