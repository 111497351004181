import React, {useState, useEffect} from 'react'
import { IoShirtSharp } from "react-icons/io5";
import "./topFees.scss"
import PropTypes from 'prop-types';

/**
 * Component for displaying top players with highest fees.
 * @component TopFees
 * @param {object[]} players - Array of player objects.
 * @returns {JSX.Element} TopFees component JSX
 */

function TopFees({players}) {
    const [totalFees, setTotalFees] = useState(0);

    // Sort players by player fee in descending order
    const sortedPlayers = [...players].sort((a, b) => b.playerFee - a.playerFee);

    // Get top 5 players
    const topPlayers = sortedPlayers.slice(0, 5);

    /**
     * Calculates the total fee of all players.
     * @function
     * @name calcTotalFee
     */
    const calcTotalFee = () => {
        let total = 0; 

        players.forEach(player => {
            total = total + player.playerFee;
        })
        setTotalFees(total);  
    }

    // useEffect to handle calcTotalFee state
    useEffect(() => {
        calcTotalFee();
    }, [players]);

    return (
    <div className='top-fees'>
        <h1>Top Fees</h1>
        {totalFees !== 0 ? <p>Total Fees: {totalFees} €</p> : <p>Assign penalties to players to see the Top Fees List.</p>}
        <div className="player-list">
        {topPlayers.map((player) => (
            <div className='player-list-item' key={player._id}>
            <div className='player-item'>
                <div className="player-number">
                <IoShirtSharp/>{player.number}
                </div>
                <div className="player-text">
                <div className="player-surname">
                    {player.playerSurname}
                </div>
                <div className="player-name">
                    {player.playerName}
                    </div>
                </div>
            </div>
            <div className='player-fee'>{player.playerFee}€</div>
            </div>                               
        ))}
        </div>
    </div>
  )
}

TopFees.propTypes = {
    /**
     * Array of player objects.
     */
    players: PropTypes.array.isRequired,
    /**
     * Function to calculate the total fee of all players.
     */
    calcTotalFee: PropTypes.func
};

export default TopFees
