import {Link, useNavigate} from "react-router-dom"
import { MdDashboardCustomize } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa"
import { IoShirtSharp } from "react-icons/io5";
import { FaSackDollar, FaClipboardList } from "react-icons/fa6";
import {logout, reset} from "../../features/auth/authSlice"
import { useDispatch } from 'react-redux';
import "./menu.scss"
import PropTypes from "prop-types";

/**
 * Custom Menu Component:
 * This component provides a customizable menu for navigation within the application.
 * @component Menu
 * @returns {JSX.Element} Menu component JSX
 */

function Menu(){
    const dispatch = useDispatch()
    const navigate = useNavigate()

    /**
     * Handles the logout functionality
     * Dispatches logout and reset actions and navigates to the home page ("/")
     * @function
     * @name onLogout
     */
    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate("/")
    }

    return(
        <div className="menu">
            <div className="menu-item">
                <span className="menu-title">MAIN</span>
                <Link to="/dashboard" className="menu-link">
                    <MdDashboardCustomize /> <span>Dashboard</span>
                </Link>
            </div>
            <div className="menu-item">
                <span className="menu-title">LISTS</span>
                <Link to="/players" className="menu-link">
                    <IoShirtSharp/> <span>Players</span>
                </Link>
                <Link to="/penalties" className="menu-link">
                    <FaSackDollar/> <span>Penalties</span>
                </Link>
                <Link to="/memberships" className="menu-link">
                    <FaClipboardList/> <span>Memberships</span>
                </Link>
            </div>
            <div className="menu-item">
                <span className="menu-title">OTHER</span>
                <Link to="/" className="menu-link" onClick={onLogout}>
                <FaSignOutAlt />
                    <span>Logout</span>
                </Link>             
            </div>
        </div>
    );
}

Menu.propTypes = {
    /**
     * Handles the logout functionality
     */
    onLogout: PropTypes.func,
  };

export default Menu;