import React, { useState } from 'react'
import { FaSave } from "react-icons/fa";
import {useDispatch} from "react-redux"
import { updateMembership } from '../../features/memberships/membershipSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

/**
 * Component for editing a membership.
 * @module MembershipFormEdit
 * @param {Object} membershipEditData - The data of the membership to be edited.
 * @returns {JSX.Element} MembershipFormEdit component.
 */

function MembershipFormEdit({membershipEditData}) {
  const [membershipData, setMembershipData] = useState({
    membershipId: membershipEditData._id,
    membershipName: membershipEditData.membershipName,
    membershipStudentFee: membershipEditData.membershipStudentFee,
    membershipEmployeeFee: membershipEditData.membershipEmployeeFee,
    membershipStatus: membershipEditData.membershipStatus
  })

  const {/*membershipId,*/ membershipName, membershipStudentFee, membershipEmployeeFee, membershipStatus} = membershipData
  const dispatch = useDispatch()

  /**
   * Handles form submission to update the membership.
   * @param {Event} e - Form submission event.
   * @returns {void}
   */
  const onSubmit = (e) => {
    e.preventDefault()

    if(!membershipName || !membershipStudentFee || !membershipEmployeeFee){
      toast.info("Membership Infos cannot be empty. Enter all Membership Infos.")
    } else {
      dispatch(updateMembership(membershipData));
      toast.success("Membership updated!")
    }
  }

  /**
   * Handles input change in the form fields.
   * @param {Event} e - Input change event.
   * @returns {void}
   */
  const onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setMembershipData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  return (
    <section className='form'>
      <form onSubmit={onSubmit}>        
        <div className="form-group">
          <label htmlFor='membershipName'>Name</label>
          <input type="text" name="membershipName" id="membershipName" value={membershipName} onChange={onChange} placeholder="Membership Name" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='membershipStudentFee'>Student Fee</label>
          <input type="number" name="membershipStudentFee" id="membershipStudentFee" value={membershipStudentFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$"/>
        </div>
        <div className="form-group">
          <label htmlFor='membershipEmployeeFee'>Employee Fee</label>
          <input type="number" name="membershipEmployeeFee" id="membershipEmployeeFee" value={membershipEmployeeFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$"/>
        </div>
        <div className='form-group-checkbox-container'>
          <label htmlFor='membershipStatus'>Status</label>
          <div className='form-group-checkbox'>
            <input type='checkbox' name='membershipStatus' id='membershipStatus' className="toggle-checkbox" checked={membershipStatus} onChange={onChange} />
            <label htmlFor='membershipStatus'>{membershipStatus ? 'Active*' : 'Not Active'}</label>
          </div>          
        </div>
        <div className="form-group">
          <button className="btn btn-block-modal" type='submit'><FaSave /> Save Changes</button>
        </div>
        {membershipStatus && (
          <p className='membership-note'>* active memberships are automatically added to every player on the first day of every month</p>
        )}
      </form>
    </section>
  )
}

MembershipFormEdit.propTypes = {
  /**
   * The data of the membership to be edited.
   */
  membershipEditData: PropTypes.object,
  /**
   * Function to handle form submission.
   */
  onSubmit: PropTypes.func,
  /**
   * Function to handle input change.
   */
  onChange: PropTypes.func,
};


export default MembershipFormEdit
