import React, {useState, useEffect} from 'react'
import { MdAssignmentTurnedIn } from "react-icons/md";
import { toast } from 'react-toastify';
import {useDispatch} from "react-redux"
import { updatePlayer } from '../../../features/players/playerSlice';
import "./assignment.scss"
import PropTypes from 'prop-types';

/**
 * Component for assigning penalties to players.
 * @component Assignment
 * @param {Object[]} players - The list of players.
 * @param {Object[]} penalties - The list of penalties.
 * @returns {JSX.Element} Assignment component JSX
 */

function Assignment({players, penalties}) {
    const dispatch = useDispatch()

    // State to control sorting
    const [sortedPlayers, setSortedPlayers] = useState([]);
    const [sortOrderPlayers, setSortOrderPlayers] = useState('asc');

    // State to control sorting
    const [sortedPenalties, setSortedPenalties] = useState([]);
    const [sortOrderPenalties, setSortOrderPenalties] = useState('asc');

    // State to store selected player, penalty, and notes
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [selectedPenalty, setSelectedPenalty] = useState('');
    const [selectedReps, setSelectedReps] = useState(1);
    const [selectedNote, setSelectedNote] = useState('');

    /**
     * Gets the current date.
     * @returns {string}
     */
    const getCurrentDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    /**
     * Function to handle assigning penalties to players.
     * @function
     * @name handleAssign
     */
    const handleAssign = () => {     
        if (selectedReps > 50 || selectedReps < 1) {
            // Show toast notification if Reps < 1 or > 50
            toast.warning('Choose a Rep Value between 1 and 50.');
            return;
        }
        
        if (!selectedPlayer || !selectedPenalty) {
            // Show toast notification if either player or penalty is not selected
            toast.warning('Both player and penalty must be selected before assignment.');
            return;
        }

        if(selectedPlayer === "Add to all Players"){
            // Find the selected penalty by their IDs
            const penalty = penalties.find(penalty => penalty._id === selectedPenalty);
            
            // Assign penalty to all players
            players.forEach(player => {
                const updatedFee = player.playerFee + (selectedReps * penalty.penaltyFee)
                const updatedPenaltyName = selectedReps + 'x ' + penalty.penaltyName
                const updatedPenaltyFee = selectedReps !== 1 ? `${selectedReps}*${penalty.penaltyFee} (${selectedReps * penalty.penaltyFee})` : penalty.penaltyFee;
                const updatedNote = selectedNote ? `Note: ${selectedNote}`:`Note: Date of Assignment ${getCurrentDate()}`;
                const updatedFeeHistory = [updatedPenaltyName, updatedPenaltyFee, updatedNote]
                
                const updatedPlayerData = {
                    playerId: player._id,
                    number: player.number,
                    playerSurname: player.playerSurname,
                    playerName: player.playerName,
                    playerPhone: player.playerPhone,
                    playerFee: updatedFee,
                    playerStudentStatus: player.playerStudentStatus,
                    playerFeeHistory: player.playerFeeHistory.length > 0 ? [...player.playerFeeHistory, updatedFeeHistory] : [updatedFeeHistory]
                };

                dispatch(updatePlayer(updatedPlayerData));
            });

            // Reset selected values
            setSelectedPenalty('');
            setSelectedPlayer('');
            setSelectedReps('1');
            setSelectedNote('');

            toast.success(`Penalty "${penalty.penaltyName}" assigned to all players!`);
    
        } else {
            // Find the selected player and penalty by their IDs
            const player = players.find(player => player._id === selectedPlayer);
            const penalty = penalties.find(penalty => penalty._id === selectedPenalty);

            const updatedFee = player.playerFee + (selectedReps * penalty.penaltyFee)
            const updatedPenaltyName = selectedReps + 'x ' + penalty.penaltyName
            const updatedPenaltyFee = selectedReps !== 1 ? `${selectedReps}*${penalty.penaltyFee} (${selectedReps * penalty.penaltyFee})` : penalty.penaltyFee;
            const updatedNote = selectedNote ? `Note: ${selectedNote}`:`Note: Date of Assignment ${getCurrentDate()}`;
            const updatedFeeHistory = [updatedPenaltyName, updatedPenaltyFee, updatedNote]

            const updatedPlayerData = {
                playerId: player._id,
                number: player.number,
                playerSurname: player.playerSurname,
                playerName: player.playerName,
                playerPhone: player.playerPhone,
                playerFee: updatedFee,
                playerStudentStatus: player.playerStudentStatus,
                playerFeeHistory: player.playerFeeHistory.length > 0 ? [...player.playerFeeHistory, updatedFeeHistory] : [updatedFeeHistory]
            };

            dispatch(updatePlayer(updatedPlayerData));

            // Reset selected values
            setSelectedPlayer('');
            setSelectedPenalty('');
            setSelectedReps('1');
            setSelectedNote('');

            toast.success(`Penalty "${penalty.penaltyName}" assigned to Player "${player.number} - ${player.playerSurname}, ${player.playerName}"!`)
        }
    };

    // useEffect to handle sorting players based on their number and updating the sorted player state.
    useEffect(() => {
        // Sort players by number
        const sorted = [...players].sort((a, b) => {
          return sortOrderPlayers === 'asc' ? a.number - b.number : b.number - a.number;
        });
        setSortedPlayers(sorted);
    }, [players, sortOrderPlayers]);

    // useEffect to handle sorting penalties.
    useEffect(() => {
        // Sort penalties by name
        const sorted = [...penalties].sort((a, b) => {
            return sortOrderPenalties === 'asc' ? a.penaltyName.localeCompare(b.penaltyName) : b.penaltyName.localeCompare(a.penaltyName);
        });
        setSortedPenalties(sorted);
    }, [penalties, sortOrderPenalties]);

  return (
    <div className='assignment'>
        <div className='assignment-box box-assign-heading'>
            <h1>Assignment</h1>
        </div>
        <div className='assignment-box box-player'>
            <span>Choose Player</span>
            <select value={selectedPlayer} onChange={(e) => setSelectedPlayer(e.target.value)}>
                <option>Select a Player</option>
                <option>Add to all Players</option>
                {sortedPlayers.map((player) => (
                    <option key={player._id} value={player._id}>{player.number} - {player.playerSurname}, {player.playerName} </option>                        
                ))}
            </select>
        </div>
        <div className='assignment-box box-assign'>
            <h4>Assign Penalty</h4>
            <button onClick={handleAssign} className='btn'>
                <MdAssignmentTurnedIn className='icon icons-table-item-right'/>
                assign
            </button>
        </div> 
        <div className='assignment-box box-penalty'>
            <span>Choose Penalty</span>
            <select value={selectedPenalty} onChange={(e) => setSelectedPenalty(e.target.value)}>
                <option>Select a Penalty</option>                                
                {sortedPenalties.map((penalty) => (                                                                     
                    <option key={penalty._id} value={penalty._id}>{penalty.penaltyName} - {penalty.penaltyFee} </option>                        
                ))}
            </select>
        </div>
        <div className='assignment-box box-note'>
            <span>Add Note (Optional)</span>
            <input type='number' placeholder='Specify Reps' defaultValue={1} min={1} max={50} value={selectedReps} onChange={(e) => setSelectedReps(e.target.value)}></input>
            <br></br>
            <input type='text' placeholder='Add Note' value={selectedNote} onChange={(e) => setSelectedNote(e.target.value)}></input>            
        </div>
        <div className='assignment-box box-assign-sm'>
            <h4>Assign Penalty</h4>
            <button onClick={handleAssign} className='btn'>
                <MdAssignmentTurnedIn className='icons-table-item-right'/>
                assign
            </button>
        </div>
    </div>
  )
}

Assignment.propTypes = {
    /**
     * The list of players to assign penalties to.
     */
    players: PropTypes.array.isRequired,
    /**
     * The list of penalties to assign to players.
     */
    penalties: PropTypes.array.isRequired,
    /**
     * Function to handle assigning penalties to players.
     */
    handleAssign: PropTypes.func,
    /**
     * Function to retrieve the current date.
     */
    getCurrentDate: PropTypes.func,
};

export default Assignment
