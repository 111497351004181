import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Home from "./pages/Home/Home"
import Dashboard from "./pages/Dashboard/Dashboard"
import Players from "./pages/Players/Players"
import Penalties from "./pages/Penalties/Penalties"
import Login from "./pages/Login/Login"
import Register from "./pages/Register/Register"
import Header from "./components/UI/Header/Header"
import Footer from "./components/UI/Footer/Footer"
import Memberships from "./pages/Memberships/Memberships"
import Menu from "./components/Menu/Menu"
import NoPage from "./pages/NoPage/NoPage"
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';

/**
 * Layout component for the application.
 * @component Layout
 * @returns {JSX.Element} Layout component JSX
 */
const Layout = () => {
  const {user} = useSelector((state) => state.auth)

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return(
    <ThemeProvider theme={darkTheme}>
      <div className="main">
        <Header/>
        <div className="container-main">
          {!user &&
            <div className="layout-wave-container">
              <div className="layout-wave"></div>
              <div className="layout-wave"></div>
              <div className="layout-wave"></div>
            </div>
          }                 
          <div className="container">
            {user && 
              <div className="menuContainer">
                <Menu/>
              </div>
            }
            <div className="contentContainer  overlay">
              <Outlet/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </ThemeProvider>
  )
}

/**
 * App component for the application.
 * @component App
 * @returns {JSX.Element} App component JSX
 */

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>} />
            <Route path="login" element={<Login/>} />
            <Route path="register" element={<Register/>} />
            <Route path="dashboard" element={<Dashboard/>} />
            <Route path="players" element={<Players/>} />
            <Route path="penalties" element={<Penalties/>} />
            <Route path="memberships" element={<Memberships/>} />
            <Route path="*" element={<NoPage/>} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer autoClose={2500} newestOnTop={true} theme="dark"/>
    </>
  );
}

export default App;
