import {React, useState, useEffect} from "react"
import {useSelector, useDispatch } from "react-redux"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"
import {FaUser} from "react-icons/fa"
import {register, reset} from "../../features/auth/authSlice"
import Spinner from "../../components/UI/Spinner"
import { FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import "./register.scss"

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  })

  const {name, email, password, confirmPassword} = formData

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user, isLoading, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )

  // useEffect to handle registration
  useEffect(() => {
    if(isError){
      toast.error(message)
    } 

    if(isSuccess){
      navigate("/login")
      toast.success("Registration was successful!")
    }

    if(user){
      navigate("/dashboard")
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    
    if(password !== confirmPassword){
      toast.error("Passwords do not match.")
    }  else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}/.test(password)) {
      toast.error("Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special symbol, and be 8-12 characters long.");
    } else {
      const userData = {
        name,
        email,
        password,
      }
      dispatch(register(userData))
    }
  }

  if(isLoading){
    return(<Spinner/>)
  }

  return (
    <div className="register-page">
      <section className="heading">
        <h1>
          <FaUser/> Register
        </h1>
        <p>Create Account</p>
      </section>

      <section className="form-register">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label><FaUser className="label-icon"/> Name</label>
            <input type="text" className="form-control" id="name" name="name" value={name} placeholder="Enter Your Name" onChange={onChange}/>
          </div>
          <div className="form-group">
            <label><MdEmail className="label-icon"/> Email</label>
            <input type="email" className="form-control" id="email" name="email" value={email} placeholder="Enter Your Email" onChange={onChange}/>
          </div>
          <div className="form-group">
            <label><FaLock className="label-icon"/>  Password</label>
            <input type="password" className="form-control" id="password" name="password" value={password} placeholder="Enter Password" onChange={onChange} onFocus={() => setIsPasswordFocused(true)} onBlur={() => setIsPasswordFocused(false)} pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}" title='Must contain at least one number, one uppercase and one lowercase letter as well as at least one special symbol (!@#$%^&*=+-_) and minimun 8 and maximun 12 characters!'/>
            {isPasswordFocused && (
              <div id="message">
                <h3>Password must contain ...</h3>
                <p id="letter">... a <b className="pwdCheck">lowercase</b> letter</p>
                <p id="capital">... a <b className="pwdCheck">capital (uppercase)</b> letter</p>
                <p id="number">... a <b className="pwdCheck">number</b></p>
                <p id="special">... a <b className="pwdCheck">special symbol </b>(<b className="pwdCheck"> !@#$%^&*=+-_ </b>)</p>
                <p id="length">... <b className="pwdCheck">8-12 characters</b></p>
              </div>
            )}
          </div>
          <div className="form-group">
            <label><FaLock className="label-icon"/> Confirm Password</label>
            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" value={confirmPassword} placeholder={"Confirm Password"} onChange={onChange}/>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-block">Register</button>
          </div>
          <div className="form-group">
            <p>Already have an account? <a className="login-link" href="/login">Log in!</a></p>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Register
