import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import "./noPage.scss"

/**
 * NoPage component renders a 404 error page when a route is not found.
 * It displays an error message and provides options to navigate back to the dashboard or home page.
 * @component
 */

function NoPage() { 
  // Select authentication state from Redux store 
  const {user} = useSelector((state) => state.auth)

  return (
    <div className="noPage">
      <div className="noPage-box">
        <div className="error-code">
          <img src="./img/404-error.webp" alt="404 Error gif"/>
        </div>
        <div className="error-message">
          <h3>Error: 404 page not found</h3>
          <p>Sorry, the page you're looking for cannot be accessed</p>
          {user ? 
            <Link to="/dashboard">
              <button>Go Back to Dashboard</button>
            </Link>
            : 
            <Link to="/">
              <button>Go Back to Home</button>
            </Link>
          }
        </div>
      </div>     
    </div>
  )
}

export default NoPage
