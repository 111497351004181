import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import playerService from "./playerService"

/**
 * Initial state for the player slice.
 * @type {Object}
 * @property {Array} players - Array to hold player data.
 * @property {boolean} isError - Indicates if an error occurred.
 * @property {boolean} isSuccess - Indicates if the operation was successful.
 * @property {boolean} isLoading - Indicates if a request is in progress.
 * @property {string} message - Error message if an error occurred.
 */
const initialState = {
    players: [],
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message: ""
}

/**
 * Thunk to create a new player.
 * @function createPlayer
 * @param {Object} playerData - Data for creating the player.
 * @returns {AsyncThunk<Promise<Object>, any, {}>} An asynchronous thunk that creates a new player.
 */
export const createPlayer = createAsyncThunk("players/create", async (playerData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await playerService.createPlayer(playerData, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to retrieve user players.
 * @function getPlayers
 * @returns {AsyncThunk<Promise<Object>, any, {}>} An asynchronous thunk that retrieves user players.
 */
export const getPlayers = createAsyncThunk("players/getPlayers", async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await playerService.getPlayers(token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
* Thunk to delete a player.
* @function deletePlayer
* @param {string} id - ID of the player to delete.
* @returns {AsyncThunk<Promise<Object>, any, {}>} An asynchronous thunk that deletes a player.
*/
export const deletePlayer = createAsyncThunk("players/delete", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await playerService.deletePlayer(id, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to update a player.
 * @function updatePlayer
 * @param {Object} playerData - Updated data for the player.
 * @returns {AsyncThunk<Promise<Object>, any, {}>} An asynchronous thunk that updates a player.
 */
export const updatePlayer = createAsyncThunk('players/update', async (playerData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        // Make API call to update player
        const updatedPlayer = await playerService.updatePlayer(playerData, token);
        return updatedPlayer;
      } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
      }
})

/**
 * Slice containing player-related state and reducers.
 */
export const playerSlice = createSlice({
    name: "player",
    initialState,
    reducers: {
        resetPlayer: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPlayer.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createPlayer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.players.push(action.payload)
            })
            .addCase(createPlayer.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getPlayers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPlayers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.players = action.payload
            })
            .addCase(getPlayers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deletePlayer.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deletePlayer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.players = state.players.filter((player) => player._id !== action.payload.id)
            })
            .addCase(deletePlayer.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updatePlayer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updatePlayer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.players = state.players.map(player => player._id === action.payload._id ? action.payload : player);
            })
            .addCase(updatePlayer.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })         
    }
})


/**
 * Exports actions and reducers from the player slice.
 */
export const {resetPlayer} = playerSlice.actions
export default playerSlice.reducer