import { FaGithub, FaRegCopyright, FaLinkedin, FaInstagram } from "react-icons/fa";
import {Link} from "react-router-dom"
import { useSelector } from 'react-redux'
import "./footer.scss"

/**
 * Footer component for the application.
 * @component Footer
 * @returns {JSX.Element} Footer component JSX
 */

function Footer(){
    const {user} = useSelector((state) => state.auth)

    return(
        <>
        <footer className="footer">
            <ul className="logo-footer">
                <div>
                    <li>
                        <Link className="link-footer" to="/"><img src="/img/logo512.png" width="50" height="50" alt='PenPro Logo' /></Link>
                    </li>
                    <li>
                        <Link className="link-footer" to="/"><h3>PenPro</h3></Link>
                    </li>
                </div>
                <div className="social-media">
                    {/*<li>
                        <a className="link-footer footer-icon" href="https://github.com/NaBo-00" target="_blank" rel="noreferrer"><FaGithub/></a>                    
                    </li>*/}
                    <li>                    
                        <a className="link-footer footer-icon" href="https://www.instagram.com/penpro.online" target="_blank" rel="noreferrer"><FaInstagram /></a>
                    </li>
                    {/*<li>                    
                        <a className="link-footer footer-icon" href="https://www.linkedin.com/in/nareg-niko-boyagian" target="_blank" rel="noreferrer"><FaLinkedin /></a>
                    </li>*/}
                </div>
                <li>
                    <small><FaRegCopyright /> 2024 PenPro, All Rights Reserved.</small>
                </li>
            </ul>
            <ul className="menu-footer">
                {user ? (<>
                    <li>
                        <h3>Menu</h3>
                    </li>
                    <li>
                        <Link className="link-footer" to="/dashboard">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link className="link-footer" to="/memberships">
                            Memberships
                        </Link>
                    </li>
                    <li>
                        <Link className="link-footer" to="/players">
                            Players
                        </Link>
                    </li>
                    <li>
                        <Link className="link-footer" to="/penalties">
                            Penalties
                        </Link>
                    </li></>) 
                    : (<>
                    <li>
                        <h3>Menu</h3>
                    </li>
                    <li>
                        <Link className="link-footer" to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className="link-footer" to="/login">
                            Login
                        </Link>
                    </li>
                    <li>
                        <Link className="link-footer" to="/register">
                            Register
                        </Link>
                    </li></>)
                }
            </ul>
            <ul className="contact-footer">
                <li>
                    <h3>Contact</h3>
                </li>
                <li>
                    <a className="link-footer" href= "mailto:penpro.helpdesk@gmail.com?subject=Ticket">penpro.helpdesk@gmail.com</a>
                </li>
            </ul>
        </footer>
    </>
    );
}

export default Footer;