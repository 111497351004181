import { React, useState } from 'react'
import { FaSave } from "react-icons/fa";
import {useDispatch} from "react-redux"
import { updatePlayer } from '../../features/players/playerSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

/**
 * Component for editing player information.
 * @module PlayerFormEdit
 * @param {Object[]} players - An array containing existing player objects.
 * @param {Object} playerEditData - Data of the player being edited.
 * @returns {JSX.Element} PlayerFormEdit component.
 */

function PlayerFormEdit({players, playerEditData}) {
  const [playerData, setPlayerData] = useState({
    playerId: playerEditData._id,
    number: playerEditData.number,
    playerSurname: playerEditData.playerSurname,
    playerName: playerEditData.playerName,
    playerPhone: playerEditData.playerPhone,
    playerFee: playerEditData.playerFee,
    playerStudentStatus: playerEditData.playerStudentStatus,
  })

  const {/*playerId,*/ number, playerSurname, playerName, playerPhone, playerFee, playerStudentStatus} = playerData
  const dispatch = useDispatch()
  
  /**
   * Handles form submission for updating player information.
   * @function
   * @name onSubmit
   * @param {Event} e
   * @returns {void}
   */  
  const onSubmit = (e) => {
    e.preventDefault()
    
    if (!number || !playerSurname || !playerName || !playerPhone) {
      toast.info("Player Infos cannot be empty. Enter all Player Infos.");
      return;
    }
  
    if (playerEditData.number !== parseInt(number) && players.some((player) => player.number === parseInt(number))) {
      toast.info('Number already exists! Please choose a different Number.');
      return;
    }
  
    dispatch(updatePlayer(playerData));
    toast.success("Player updated!");
  }

  /**
   * Handles input change for player information fields.
   * @function
   * @name onChange
   * @param {Event} e - The input change event.
   * @returns {void}
   */
  const onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setPlayerData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }))
  }

  return (
    <section className='form'>
      <form onSubmit={onSubmit}>        
        <div className="form-group">
          <label htmlFor='number'>Number</label>
          <input type="number" min="0" max="99" name="number" id="number" value={number} onChange={onChange} placeholder="Players Number"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerSurname'>Surname</label>
          <input type="text" name="playerSurname" id="playerSurname" value={playerSurname} onChange={onChange} placeholder="Players Surname" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerName'>Name</label>
          <input type="text" name="playerName" id="playerName" value={playerName} onChange={onChange} placeholder="Players Name" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerPhone'>Phone</label>
          <input type="tel" name="playerPhone" id="playerPhone" value={playerPhone} onChange={onChange} placeholder="Enter Phone Number (Format: +49123123456)" pattern="^\+49[1-9][0-9]{1,14}$"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerFee'>Fee</label>
          <input type="number" name="playerFee" id="playerFee" value={playerFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$" disabled/>
        </div>
        <div className='form-group-checkbox-container'>
          <label htmlFor='Status'>Status</label>        
          <div className="form-group-checkbox"> 
            <input type="checkbox" name="playerStudentStatus" id="playerStudentStatus" className='toggle-checkbox' checked={playerStudentStatus} onChange={onChange} />
            <label htmlFor="playerStudentStatus">{playerStudentStatus ? 'Student' : 'Employee'}</label>
          </div>
        </div>
        <div className="form-group">
          <button className="btn btn-block-modal" type='submit'><FaSave /> Save Changes</button>
        </div>
      </form>
    </section>
  )
}

PlayerFormEdit.propTypes = {
  /**
   * An array containing existing player objects.
   */
  players: PropTypes.array.isRequired,
  /**
   * Data of the player being edited.
   */
  playerEditData: PropTypes.object.isRequired,
  /**
   * Function to handle form submission.
   */
  onSubmit: PropTypes.func,
  /**
   * Function to handle input change.
   */
  onChange: PropTypes.func,
};

export default PlayerFormEdit
