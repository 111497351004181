import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import penaltyService from "./penaltyService"

/**
 * Initial state for the penalty slice.
 * @type {Object}
 */
const initialState = {
    penalties: [],
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message: ""
}

/**
 * Thunk to create a new penalty.
 * @async
 * @function createPenalty
 * @param {Object} penaltyData - Data for creating the penalty
 * @returns {Promise<Object>} A promise that resolves to the created penalty data
 */
export const createPenalty = createAsyncThunk("penalties/create", async (penaltyData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await penaltyService.createPenalty(penaltyData, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to retrieve user penalties.
 * @async
 * @function getPenalties
 * @returns {Promise<Object>} A promise that resolves to the user's penalties data
 */
export const getPenalties = createAsyncThunk("penalties/getPenalties", async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await penaltyService.getPenalties(token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to retrieve a specific penalty by its ID.
 * @async
 * @function getPenalty
 * @param {string} id - ID of the penalty to retrieve
 * @returns {Promise<Object>} A promise that resolves to the specified penalty data
 */
export const getPenalty = createAsyncThunk("penalties/getPenalty", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await penaltyService.getPenalty(id, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to delete a penalty.
 * @async
 * @function deletePenalty
 * @param {string} id - ID of the penalty to delete
 * @returns {Promise<Object>} A promise that resolves to the deleted penalty data
 */
export const deletePenalty = createAsyncThunk("penalties/delete", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await penaltyService.deletePenalty(id, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to update a penalty.
 * @async
 * @function updatePenalty
 * @param {Object} penaltyData - Updated data for the penalty
 * @returns {Promise<Object>} A promise that resolves to the updated penalty data
 */
export const updatePenalty = createAsyncThunk('penalties/update', async (penaltyData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      // Make API call to update penalty
      const updatedPenalty = await penaltyService.updatePenalty(penaltyData, token);
      return updatedPenalty;
    } catch (err) {
      const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
      return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Slice for managing penalties state.
 * @type {Object}
 */
export const penaltySlice = createSlice({
    name: "penalty",
    initialState,
    reducers: {
        resetPenalty: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPenalty.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createPenalty.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.penalties.push(action.payload)
            })
            .addCase(createPenalty.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getPenalties.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPenalties.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.penalties = action.payload
            })
            .addCase(getPenalties.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deletePenalty.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deletePenalty.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.penalties = state.penalties.filter((penalty) => penalty._id !== action.payload.id)
            })
            .addCase(deletePenalty.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            }) 
            .addCase(getPenalty.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPenalty.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.penalties = state.penalties.filter((penalty) => penalty._id !== action.payload.id)
            })
            .addCase(getPenalty.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updatePenalty.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updatePenalty.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.penalties = state.penalties.map(penalty => penalty._id === action.payload._id ? action.payload : penalty);
            })
            .addCase(updatePenalty.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })                 
    }
})

/**
 * Exports action creators and reducer for the penalty slice.
 */

export const {resetPenalty} = penaltySlice.actions
export default penaltySlice.reducer