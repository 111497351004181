import axios from "axios"

const API_URL = "/api/users/"

/**
 * Service for user authentication operations.
 * @module authService
 */

/**
 * Registers a new user.
 * @async
 * @function register
 * @memberof module:authService
 * @param {Object} userData - User data to register
 * @returns {Promise<Object>} A promise that resolves to the response data from the API
 */
const register = async (userData) => {
    const response = await axios.post(API_URL, userData)

    if(response.data){
        localStorage.setItem("user", JSON.stringify(response.data))
    }

    return response.data
}

/**
 * Logs in a user.
 * @async
 * @function login
 * @memberof module:authService
 * @param {Object} userData - User data to log in
 * @returns {Promise<Object>} A promise that resolves to the response data from the API
 */
const login = async (userData) => {
    const response = await axios.post(API_URL + "login", userData)

    if(response.data){
        localStorage.setItem("user", JSON.stringify(response.data))
    }

    return response.data
}

/**
 * Logs out the current user.
 * @function logout
 * @memberof module:authService
 */
const logout = () => {
    localStorage.removeItem("user")
}

/**
 * Object containing authentication service functions.
 * @namespace authService
 * @property {Function} register - Function to register a new user
 * @property {Function} logout - Function to log out the user
 * @property {Function} login - Function to log in a user
 */
const authService = {
    register,
    logout,
    login
}


export default authService