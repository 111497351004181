import {React, useState, useEffect} from "react"
import {FaSignInAlt} from "react-icons/fa"
import {useSelector, useDispatch } from "react-redux"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"
import {login, reset} from "../../features/auth/authSlice"
import Spinner from "../../components/UI/Spinner"
import { FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import "./login.scss"

/**
 * Login component renders the login page of the application.
 * It allows users to input their email and password to authenticate.
 * Upon successful login, it redirects the user to the dashboard.
 * @component
 */

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const {email, password} = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Select authentication state from Redux store
  const {user, isLoading, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )

  // useEffect hook to handle login process
  useEffect(() => {
    // Display error toast if login fails
    if(isError){
      toast.error(message)
    } 

    // Redirect user to dashboard upon successful login
    if(isSuccess || user){
      navigate("/dashboard")
    }

    // Display success toast after successful login
    if(isSuccess){
      toast.success("Login was successful!")
    }

    // Reset authentication state after login attempt
    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  // Function to handle input change
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  // Function to handle form submission
  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password
    }

     // Dispatch login action
    dispatch(login(userData))
  }

  if(isLoading){
    return <Spinner/>
  }

  return (
    <div className="login-page">
      <section className="heading">
        <h1>
          <FaSignInAlt/> Login
        </h1>
        <p>Log Into Account</p>
      </section>

      <section className="form-login">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label><MdEmail className="label-icon"/> Email</label>
            <input type="email" className="form-control" id="email" name="email" value={email} placeholder="Enter Your Email" onChange={onChange}/>
          </div>
          <div className="form-group">
            <label><FaLock className="label-icon"/> Password</label>
            <input type="password" className="form-control" id="password" name="password" value={password} placeholder="Enter Password" onChange={onChange}/>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-block">Log in</button>
          </div>
          <div className="form-group">
            <p>Don't have an account? <a className="register-link" href="/register">Sign up!</a></p>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Login
