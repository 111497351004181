import React from 'react'
import { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import Spinner from "../../components/UI/Spinner"
import { getMemberships, resetMembership } from '../../features/memberships/membershipSlice'
import MembershipTable from '../../components/Memberships/MembershipTable'
import MembershipModalAdd from '../../components/Memberships/MembershipModalAdd'
import "./memberships.scss"

/**
 * Memberships component renders the memberships management page.
 * It fetches the list of memberships from the server and displays them in a table.
 * It allows users to add new memberships via a modal.
 * @component
 */

function Memberships() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

   // Select authentication state from Redux store
  const {user} = useSelector((state) => state.auth)
  // Select memberships state from Redux store
  const {memberships, isLoading, isError, message} = useSelector((state) => state.memberships)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setEdit] = useState(null);

  // Function to open the add membership modal
  const openModalAdd = () => {
    setIsModalOpen(true);
    setEdit(false);
  }
  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

  // useEffect hook to fetch memberships data and handle error/success
  useEffect(() => {
    // Display error message if there is an error
    if(isError) {
      console.log(message)
    }

    // Redirect user to login page if not authenticated
    if(!user) {
      navigate("/login")
    }

    // Fetch memberships data from the server
    dispatch(getMemberships())

    // Cleanup function to reset memberships state
    return () => {
      dispatch(resetMembership())
    }
  }, [user, navigate, isError, message, dispatch])

  if(isLoading){
    return <Spinner/>
  }

  return (
    <>
      <div className="manage-memberships">
        <div className="memberships-info">
          <h1>Manage Memberships</h1>
          <button onClick={openModalAdd}>Add New Membership</button>
        </div>
        <MembershipTable memberships={memberships}/>
        <MembershipModalAdd isModalOpen={isModalOpen} onCloseModal={closeModal} memberships={memberships}/>  
      </div>
    </>
  )
}

export default Memberships
