import React, {useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import "./penaltyTable.scss"
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {deletePenalty} from "../../features/penalties/penaltySlice"
import PenaltyModalEdit from './PenaltyModalEdit';
import PropTypes from 'prop-types';

import { FiDownload } from "react-icons/fi"; // Import the download icon
import * as XLSX from 'xlsx'; // Import the xlsx library
import jsPDF from 'jspdf'; // Import jsPDF library
import 'jspdf-autotable'; // Import jsPDF Autotable plugin

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

/**
 * Component for displaying a table of penalty information.
 * @module PenaltyTable
 * @param {Object[]} penalties - An array containing penalty objects.
 * @returns {JSX.Element} PenaltyTable component.
 */

export default function PenaltyTable({penalties}) {
    const dispatch = useDispatch()

    const [penaltyEditData, setPenaltyEditData] = useState({
        _id: "",
        penaltyName: "",
        penaltyFee: "",
    })

    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Handles penalty deletion.
     * @param {Object} penalty - The penalty object to be deleted.
     * @returns {void}
     */
    const handleDelete = (penalty) => {
        if (window.confirm(`Are you sure you want to delete "${penalty.penaltyName}"?`)) {
          dispatch(deletePenalty(penalty._id));
          toast.success(`Penalty "${penalty.penaltyName}" deleted!`);
        } else {
          toast.info('Delete penalty cancelled.');
        }
    };

    /**
     * Opens the edit modal for a penalty.
     * @param {string} _id - The ID of the penalty to edit.
     * @param {string} penaltyName - The name of the penalty to edit.
     * @param {string} penaltyFee - The fee of the penalty to edit.
     * @returns {void}
     */
    const openModalEdit = (_id, penaltyName, penaltyFee) => {
        setIsModalOpen(true);
        setPenaltyEditData({...penaltyEditData, _id, penaltyName, penaltyFee});
    }

    /**
     * Closes the edit modal.
     * @returns {void}
     */
    const closeModal = () => setIsModalOpen(false);

    /**
     * Gets the current month and year for naming the Excel file.
     * @returns {string}
     */
    const getCurrentDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    /**
     * Handles exporting penalties data to different file formats.
     * @param {string} type - The type of file to export ('csv', 'excel', 'pdf').
     * @returns {void}
     */
    const handleExport = (type) => {
      const exportData = penalties.map(penalty => ({
          "Penalty Name": penalty.penaltyName,
          "Penalty Fee": penalty.penaltyFee,
      }));

      const currentDate = getCurrentDate();

      if (type === 'csv') {
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
          const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `PenPro_Penalties_${currentDate}.csv`;
          link.click();
      } else if (type === 'excel') {
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Penalties");
          XLSX.writeFile(workbook, `PenPro_Penalties_${currentDate}.xlsx`);
      } else if (type === 'pdf') {
          const doc = new jsPDF();
          doc.autoTable({
              head: [['Penalty Name', 'Penalty Fee']],
              body: exportData.map(row => [
                  row["Penalty Name"],
                  row["Penalty Fee"]
              ]),
          });
          doc.save(`PenPro_Penalties_${currentDate}.pdf`);
      }
  };

    // Table Column definitions
    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50,
        },
        { 
            field: 'penaltyName',
            headerName: 'Penalty Name',
            width: 300,
          },
        {
          field: 'penaltyFee',
          headerName: 'Fee',
          width: 90,
          editable: false,
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 90,
            renderCell: (params) => {
                return (                    
                    <div className='action-icons'>
                        <div className="penalty-edit-icon" onClick={() => openModalEdit(params.row.action._id, params.row.action.penaltyName, params.row.action.penaltyFee)} title="Edit Penalty"><FaEdit/></div>
                        <div className="penalty-delete-icon" onClick={() => handleDelete(params.row.action)} title='Delete Penalty'><MdDeleteForever/></div>
                    </div>
                )
            }
        },
    ];
    
    // Table Row definitions
    const rows = penalties.map((penalty, index) => ({
        id: index + 1,
        penaltyName: penalty.penaltyName,
        penaltyFee: penalty.penaltyFee,
        action: penalty 
    }));

    const CustomToolbar = () => {
      return (
        <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc', padding: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/*<GridToolbarExport />*/}
            <FormControl sx={{ m: 1, width: 140, mt: 3 }}>
              <Select
                  displayEmpty
                  input={<OutlinedInput />}
                  renderValue={() => 
                    <p style={{ color: '#90caf9' }}><FiDownload style={{ marginRight: '4px' }} /> EXPORT</p>
                  }
              >                              
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('csv')} title="Export to CSV" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> CSV
                </div>
              </MenuItem>
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('excel')} title="Export to Excel" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> EXCEL
                </div>
              </MenuItem>
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('pdf')} title="Export to PDF" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> PDF
                </div>
              </MenuItem>
            </Select>
          </FormControl>
          </div>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <GridToolbarQuickFilter />
          </div>
        </GridToolbarContainer>
      );
    }
    
  return (
    <div className="penalty-table">
        <Box>
            <DataGrid
                className='penalty-grid'
                rows={rows}
                columns={columns}
                autoHeight={true}
                localeText={{ 
                  noRowsLabel: "No Penalties detected: Add Penalties!" 
                }}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 5,
                    },
                },
                }}
                slots={{ toolbar: CustomToolbar  }}
                slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                sx={{
                    boxShadow: 2,
                    border: 2,
                    '& .MuiDataGrid-cell:hover': {
                      color: "cadetblue",                    
                    },                  
                }}
            />
        </Box>
        <PenaltyModalEdit isModalOpen={isModalOpen} onCloseModal={closeModal} penaltyEditData={penaltyEditData}/>
    </div>
    
  );
}

PenaltyTable.propTypes = {
  /**
   * An array containing penalty objects.
   */
  penalties: PropTypes.array.isRequired,
  /**
   * Function to handle penalty deletion.
   */
  handleDelete: PropTypes.func,
  /**
   * Function to open the edit modal for a penalty.
   */
  openModalEdit: PropTypes.func,
  /**
   * Function to close the edit modal.
   */
  closeModal: PropTypes.func,
  /**
   * Function to retrieve the current month and year.
   */
  getCurrentDate: PropTypes.func,
  /**
   * Function to handle the export of the table content as CSV, EXCEL and PDF.
   */
  handleExport: PropTypes.func,
};