import axios from "axios"

const API_URL = "/api/players/"

/**
 * Thunk to create a new player.
 * @function createPlayer
 * @param {Object} playerData - Data for creating the player.
 * @param {string} token - User authentication token.
 * @returns {Promise<Object>} A promise that resolves with the created player data.
 */
const createPlayer = async (playerData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL, playerData, config)

    return response.data
}

/**
 * Thunk to retrieve user players.
 * @function getPlayers
 * @param {string} token - User authentication token.
 * @returns {Promise<Array>} A promise that resolves with an array of user players.
 */
const getPlayers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL, config)

    return response.data
}

/**
 * Thunk to delete a player.
 * @function deletePlayer
 * @param {string} playerId - ID of the player to delete.
 * @param {string} token - User authentication token.
 * @returns {Promise<Object>} A promise that resolves with the deleted player data.
 */
const deletePlayer = async (playerId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(API_URL + playerId, config)

    return response.data
}

/**
 * Thunk to update a player.
 * @function updatePlayer
 * @param {Object} playerData - Updated data for the player.
 * @param {string} token - User authentication token.
 * @returns {Promise<Object>} A promise that resolves with the updated player data.
 */
const updatePlayer = async (playerData, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const response = await axios.put(API_URL + playerData.playerId, playerData, config);
  
    return response.data;
}

/**
 * Service object containing functions for player-related API interactions.
 * @type {Object}
 */
const playerService = {
    createPlayer,
    getPlayers,
    deletePlayer,
    updatePlayer
}

export default playerService