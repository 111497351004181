import React from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import MembershipFormAdd from "./MembershipFormAdd"
import PropTypes from 'prop-types';

/**
 * Component for adding a membership modal.
 * @module MembershipModalAdd
 * @param {boolean} props.isModalOpen - Indicates whether the modal is open.
 * @param {Function} props.onCloseModal - Function to close the modal.
 * @returns {JSX.Element} MembershipModalAdd component.
 */

function MembershipModalAdd({isModalOpen, onCloseModal}) {
  return (
    <div>
      <Modal open={isModalOpen} onClose={onCloseModal} center classNames={{overlay: 'customOverlay', modal: 'customModal', closeButton: "closeBtn"}}>
        <h2>Add Membership</h2>
        <MembershipFormAdd closeModal={onCloseModal}/>
      </Modal>
    </div>
  )
}

MembershipModalAdd.propTypes = {
  /**
   * Indicates whether the modal is open.
   */
  isModalOpen: PropTypes.bool.isRequired,
  /**
   * Function to close the modal.
   */
  onCloseModal: PropTypes.func.isRequired,
};

export default MembershipModalAdd
