import React from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import MembershipFormEdit from "./MembershipFormEdit"
import PropTypes from 'prop-types';

/**
 * Component for editing a membership modal.
 * @module MembershipModalEdit
 * @param {boolean} props.isModalOpen - Indicates whether the modal is open.
 * @param {Function} props.onCloseModal - Function to close the modal.
 * @param {Object} props.membershipEditData - Data for editing the membership.
 * @returns {JSX.Element} MembershipModalEdit component.
 */

function MembershipModalEdit({isModalOpen, onCloseModal, membershipEditData}) {
  return (
    <div>
      <Modal open={isModalOpen} onClose={onCloseModal} center classNames={{overlay: 'customOverlay', modal: 'customModal', closeButton: "closeBtn"}}>
        <h2>Edit Membership</h2>
        <MembershipFormEdit membershipEditData={membershipEditData}/>
      </Modal>
    </div>
  )
}

MembershipModalEdit.propTypes = {
  /**
   * Indicates whether the modal is open.
   */
  isModalOpen: PropTypes.bool,
  /**
   * Function to close the modal.
   */
  onCloseModal: PropTypes.func,
  /**
   * Data for editing the membership.
   */
  membershipEditData: PropTypes.object,
};

export default MembershipModalEdit
