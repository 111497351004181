import { useEffect, useRef, useState  } from "react";
import React from 'react';
import "./demoSection.scss"

/**
 * Component for displaying a demo section with various features.
 * @component DemoSection
 * @returns {JSX.Element} DemoSection component JSX
 */

function DemoSection(){
    // const for current gridColums state
    const [gridColumns, setGridColumns] = useState("two-col");

    // Create a ref to store references to the demo boxes
    const demoRef = useRef([]);

    // UseEffect hook to observe when demo boxes enter the viewport
    useEffect(() => {
      // Options for IntersectionObserver
      const options = {
        threshold: 0.5,
      };

      // IntersectionObserver callback
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      }, options);
  
      // Observe each demo box
      demoRef.current.forEach(demo => {
        observer.observe(demo);
      });
  
      // Cleanup function
      return () => {
        observer.disconnect(); // Disconnect observer when component unmounts
      };
    }, []);

    // useEffect to handle grid template change
    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth <= 1200) {
            setGridColumns("one-col");
          } else {
            setGridColumns("two-col");
          }
        };
  
        window.addEventListener("resize", handleResize);
        handleResize(); // Initial call to set grid columns based on window size
  
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
    return(
        <div className="home-demo">
            <h1>DEMO</h1>
            {gridColumns === "one-col" ? 
                <div className='demo-section'>
                    <div className="demo-box demo-box1 demo-text" ref={el => (demoRef.current[0] = el)}>                    
                        <p>The dashboard provides a comprehensive overview of essential statistics and data, offering users a convenient snapshot of their records and total fees. This feature serves as a central hub for users to quickly assess their data and make informed decisions.</p>                                  
                        <p>In the Dashboard Demo section, users can experience firsthand how the dashboard displays crucial information, allowing them to gauge its effectiveness and usefulness in monitoring their activities.</p>                                  
                    </div>
                    <div className="demo-box demo-box2 demo-img" ref={el => (demoRef.current[1] = el)}>
                        <img src="./img/Dashboard_Page.png" width="470" height="335" alt="Dashboard Demo"/>
                    </div>                
                    <div className="demo-box demo-box3 demo-text" ref={el => (demoRef.current[3] = el)}>                    
                        <p>The Players Demo showcases the functionality of the player management system, illustrating how users can effortlessly add, remove, and edit player information. This feature streamlines player administration, ensuring that user databases remain accurate and up to date.</p>                 
                        <p>Player Management empowers users with intuitive tools to manage player data efficiently. By providing easy access to player profiles and allowing seamless editing, this feature simplifies the process of maintaining accurate player records.</p>                 
                    </div>
                    <div className="demo-box demo-box4 demo-img" ref={el => (demoRef.current[2] = el)}>
                        <img src="./img/Players_Page.png" width="470" height="335" alt="Players Demo"/>
                    </div>
                    <div className="demo-box demo-box5 demo-text" ref={el => (demoRef.current[4] = el)}>                    
                        <p>Penalty Management offers users a robust system for managing penalties. Users can add, remove, and edit penalty records with ease, facilitating the efficient handling of disciplinary actions. The Penalty Demo provides a practical demonstration of this functionality, highlighting its effectiveness in managing penalties.</p>                 
                    </div>
                    <div className="demo-box demo-box6 demo-img" ref={el => (demoRef.current[5] = el)}>
                        <img src="./img/Penalties_Page.png" width="470" height="335" alt="Penalty Demo"/>
                    </div> 
                    <div className="demo-box demo-box7 demo-text" ref={el => (demoRef.current[7] = el)}>                    
                        <p>Membership Demo showcases the Membership Management feature, which enables users to handle monthly memberships and renewals effortlessly. With streamlined processes for managing membership data, users can ensure timely renewals and maintain membership records accurately.</p>                 
                        <p>Membership Management simplifies the administration of memberships, providing users with the tools they need to oversee membership data efficiently. By automating tasks related to membership renewal and maintenance, this feature enhances user experience and ensures the smooth operation of membership systems.</p>                 
                    </div>                      
                    <div className="demo-box demo-box8 demo-img" ref={el => (demoRef.current[6] = el)}>
                        <img src="./img/Memberships_Page.png" width="470" height="335" alt="Membership Demo"/>
                    </div>
                </div> 
            :
                <div className='demo-section'>
                    <div className="demo-box demo-box1 demo-text" ref={el => (demoRef.current[0] = el)}>                    
                        <p>The dashboard provides a comprehensive overview of essential statistics and data, offering users a convenient snapshot of their records and total fees. This feature serves as a central hub for users to quickly assess their data and make informed decisions.</p>                                  
                        <p>In the Dashboard Demo section, users can experience firsthand how the dashboard displays crucial information, allowing them to gauge its effectiveness and usefulness in monitoring their activities.</p>                                  
                    </div>
                    <div className="demo-box demo-box2 demo-img" ref={el => (demoRef.current[1] = el)}>
                        <img src="./img/Dashboard_Page.png" width="470" height="335" alt="Dashboard Demo"/>
                    </div>                
                    <div className="demo-box demo-box4 demo-img" ref={el => (demoRef.current[2] = el)}>
                        <img src="./img/Players_Page.png" width="470" height="335" alt="Players Demo"/>
                    </div>
                    <div className="demo-box demo-box3 demo-text" ref={el => (demoRef.current[3] = el)}>                    
                        <p>The Players Demo showcases the functionality of the player management system, illustrating how users can effortlessly add, remove, and edit player information. This feature streamlines player administration, ensuring that user databases remain accurate and up to date.</p>                 
                        <p>Player Management empowers users with intuitive tools to manage player data efficiently. By providing easy access to player profiles and allowing seamless editing, this feature simplifies the process of maintaining accurate player records.</p>                 
                    </div>
                    <div className="demo-box demo-box5 demo-text" ref={el => (demoRef.current[4] = el)}>                    
                        <p>Penalty Management offers users a robust system for managing penalties. Users can add, remove, and edit penalty records with ease, facilitating the efficient handling of disciplinary actions. The Penalty Demo provides a practical demonstration of this functionality, highlighting its effectiveness in managing penalties.</p>                 
                    </div>
                    <div className="demo-box demo-box6 demo-img" ref={el => (demoRef.current[5] = el)}>
                        <img src="./img/Penalties_Page.png" width="470" height="335" alt="Penalty Demo"/>
                    </div> 
                    <div className="demo-box demo-box8 demo-img" ref={el => (demoRef.current[6] = el)}>
                        <img src="./img/Memberships_Page.png" width="470" height="335" alt="Membership Demo"/>
                    </div>
                    <div className="demo-box demo-box7 demo-text" ref={el => (demoRef.current[7] = el)}>                    
                        <p>Membership Demo showcases the Membership Management feature, which enables users to handle monthly memberships and renewals effortlessly. With streamlined processes for managing membership data, users can ensure timely renewals and maintain membership records accurately.</p>                 
                        <p>Membership Management simplifies the administration of memberships, providing users with the tools they need to oversee membership data efficiently. By automating tasks related to membership renewal and maintenance, this feature enhances user experience and ensures the smooth operation of membership systems.</p>                 
                    </div>                      
                </div> 
            }            
        </div>
    );
}

export default DemoSection;