import React from 'react'
import { PieChart, Pie, Cell, Tooltip  } from 'recharts';
import "./boxPieChart.scss"
import PropTypes from 'prop-types';

  const RADIAN = Math.PI / 180;
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="center">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  /**
 * Component for displaying a pie chart.
 * @component PieChartBox
 * @param {number} props.totalPlayers - Total number of players.
 * @param {number} props.totalPenalties - Total number of penalties.
 * @param {number} props.totalMemberships - Total number of memberships.
 * @returns {JSX.Element} PieChartBox component JSX
 */

export default function PieChartBox ({totalPlayers, totalPenalties, totalMemberships}) {
  const recordsData = [
    { name: 'Players', value: totalPlayers, color: '#0088FE' },
    { name: 'Penalties', value: totalPenalties, color: '#00C49F' },
    { name: 'Memberships', value: totalMemberships, color: '#FFBB28' },
  ];

  return (
    <div className="box-pie-chart">
      <h1>Overview</h1>
      <div className="pie-chart">
        <div className="container-pie-chart">
          {totalMemberships === 0 & totalPenalties === 0 & totalPlayers === 0 ? <p>Add players, penalties, and memberships to see the chart.</p> : <></>}
          <PieChart width={250} height={250}>
            <Pie
              data={recordsData}
              innerRadius={"60%"}
              outerRadius={"95%"}
              paddingAngle={5}
              dataKey="value"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {recordsData.map((item) => (
                <Cell key={item.name} fill={item.color} />
              ))}
            </Pie>
            
            <Tooltip />
          </PieChart>      
        </div>
      </div>
      <div className="options">
        {recordsData.map((item) => (
          <div className="option" key={item.name}>
            <div className="option-title">
              <div className="dot" style={{ backgroundColor: item.color }} />
              <span>{item.name}</span>
            </div>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>    
  );
}

PieChartBox.propTypes = {
  /**
   * Total number of players.
   */
  totalPlayers: PropTypes.number.isRequired,
  /**
   * Total number of penalties.
   */
  totalPenalties: PropTypes.number.isRequired,
  /**
   * Total number of memberships.
   */
  totalMemberships: PropTypes.number.isRequired,
};

