import React from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import PenaltyFormEdit from "./PenaltyFormEdit"
import PropTypes from 'prop-types';

/**
 * Component for editing a penalty modal.
 * @module PenaltyModalEdit
 * @param {boolean} isModalOpen - Flag to indicate whether the modal is open.
 * @param {Function} onCloseModal - Function to close the modal.
 * @param {Object} penaltyEditData - Data of the penalty being edited.
 * @returns {JSX.Element} PenaltyModalEdit component.
 */

function PenaltyModalEdit({isModalOpen, onCloseModal, penaltyEditData}) {
  return (
    <div>
      <Modal open={isModalOpen} onClose={onCloseModal} center classNames={{overlay: 'customOverlay', modal: 'customModal', closeButton: "closeBtn"}}>
        <h2>Edit Penalty</h2>
        <PenaltyFormEdit penaltyEditData={penaltyEditData}/>
      </Modal>
    </div>
  )
}

PenaltyModalEdit.propTypes = {
  /**
   * Flag to indicate whether the modal is open.
   */
  isModalOpen: PropTypes.bool.isRequired,
  /**
   * Function to close the modal.
   */
  onCloseModal: PropTypes.func.isRequired,
  /**
   * Data of the penalty being edited.
   */
  penaltyEditData: PropTypes.object.isRequired,
};

export default PenaltyModalEdit
