import axios from "axios"

const API_URL = "/api/memberships/"

/**
 * Service for managing memberships through API calls.
 * @namespace membershipService
 */

/**
 * Creates a new membership.
 * @async
 * @function createMembership
 * @param {Object} membershipData - Data for creating the membership
 * @param {string} token - Authentication token for authorization
 * @returns {Promise<Object>} A promise that resolves to the created membership data
 */
const createMembership = async (membershipData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL, membershipData, config)

    return response.data
}

/**
 * Retrieves user memberships.
 * @async
 * @function getMemberships
 * @param {string} token - Authentication token for authorization
 * @returns {Promise<Object>} A promise that resolves to the user's memberships data
 */
const getMemberships = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL, config)

    return response.data
}

/**
 * Deletes a membership.
 * @async
 * @function deleteMembership
 * @param {string} membershipId - ID of the membership to delete
 * @param {string} token - Authentication token for authorization
 * @returns {Promise<Object>} A promise that resolves to the deleted membership data
 */
const deleteMembership = async (membershipId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(API_URL + membershipId, config)

    return response.data
}

/**
 * Updates a membership.
 * @async
 * @function updateMembership
 * @param {Object} membershipData - Updated data for the membership
 * @param {string} token - Authentication token for authorization
 * @returns {Promise<Object>} A promise that resolves to the updated membership data
 */
const updateMembership = async (membershipData, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const response = await axios.put(API_URL + membershipData.membershipId, membershipData, config);
  
    return response.data;
}

/**
 * Exports an object containing functions for managing memberships.
 * @type {Object}
 * @memberof membershipService
 * @property {Function} createMembership - Function to create a membership
 * @property {Function} getMemberships - Function to get memberships
 * @property {Function} deleteMembership - Function to delete a membership
 * @property {Function} updateMembership - Function to update a membership
 */
const membershipService = {
    createMembership,
    getMemberships,
    deleteMembership,
    updateMembership
}

export default membershipService