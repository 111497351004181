import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/auth/authSlice"
import playerReducer from "../features/players/playerSlice"
import penaltyReducer from "../features/penalties/penaltySlice"
import membershipReducer from "../features/memberships/membershipSlice"

/**
 * Redux store configuration.
 * @constant {Object} store - Redux store object
 * @memberof module:Redux
 * @property {Object} reducer - Combined reducers object
 * @property {Function} configureStore - Function to configure the Redux store
 */

export const store = configureStore({
  reducer: {
    auth: authReducer,
    players: playerReducer,
    penalties: penaltyReducer,
    memberships: membershipReducer
  },
});
