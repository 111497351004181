import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import authService from "./authService"

//Get user from localStorage
const user = JSON.parse(localStorage.getItem("user"))

/**
 * Initial state for the authentication slice.
 * @type {Object}
 * @property {Object|null} user - The authenticated user. Defaults to `null`.
 * @property {boolean} isError - Indicates if an error occurred during authentication. Defaults to `false`.
 * @property {boolean} isSuccess - Indicates if the authentication operation was successful. Defaults to `false`.
 * @property {boolean} isLoading - Indicates if the authentication operation is in progress. Defaults to `false`.
 * @property {string} message - Message related to the authentication operation status.
 */
const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message: ""
}

/**
 * Async thunk to register a user.
 * @async
 * @function register
 * @param {Object} user - User data to register
 * @returns {Promise<Object>} A promise that resolves to the registered user data or rejects with an error message
 */
export const register = createAsyncThunk("auth/register", async (user, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Async thunk to log in a user.
 * @async
 * @function login
 * @param {Object} user - User data to log in
 * @returns {Promise<Object>} A promise that resolves to the logged-in user data or rejects with an error message
 */
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Async thunk to log out a user.
 * @async
 * @function logout
 * @returns {Promise<void>} A promise that resolves when the user is logged out
 */
export const logout = createAsyncThunk("auth/logout", async () => {
    await authService.logout()
})

/**
 * Slice for handling authentication-related state and actions.
 * @type {Slice}
 * @name authSlice
 * @property {Function} reset - Action creator to reset the authentication state.
 * @property {ReducerMapBuilder} extraReducers - Reducer map builder for handling additional action types.
 */
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) =>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ""
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = null//action.payload // this allows to directly login after registration
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
    }
})

/**
 * Action creators for the authentication slice.
 * @type {Object}
 * @namespace authSlice.actions
 * @property {Function} reset - Action creator to reset the authentication state.
 */
export const {reset} = authSlice.actions

/**
 * Reducer function for the authentication slice.
 * @type {Function}
 * @memberof authSlice
 */
export default authSlice.reducer