import axios from "axios"

const API_URL = "/api/penalties/"

/**
 * Creates a new penalty.
 * @async
 * @function createPenalty
 * @param {Object} penaltyData - Data for creating the penalty
 * @param {string} token - User authentication token
 * @returns {Promise<Object>} A promise that resolves to the created penalty data
 */
const createPenalty = async (penaltyData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL, penaltyData, config)

    return response.data
}

/**
 * Retrieves user penalties.
 * @async
 * @function getPenalties
 * @param {string} token - User authentication token
 * @returns {Promise<Object>} A promise that resolves to the user's penalties data
 */
const getPenalties = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL, config)

    return response.data
}

/**
 * Retrieves a specific penalty by its ID.
 * @async
 * @function getPenalty
 * @param {string} penaltyId - ID of the penalty to retrieve
 * @param {string} token - User authentication token
 * @returns {Promise<Object>} A promise that resolves to the specified penalty data
 */
const getPenalty = async (penaltyId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL + penaltyId, config)

    return response.data
}

/**
 * Deletes a penalty.
 * @async
 * @function deletePenalty
 * @param {string} penaltyId - ID of the penalty to delete
 * @param {string} token - User authentication token
 * @returns {Promise<Object>} A promise that resolves to the deleted penalty data
 */
const deletePenalty = async (penaltyId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(API_URL + penaltyId, config)

    return response.data
}

/**
 * Updates a penalty.
 * @async
 * @function updatePenalty
 * @param {Object} penaltyData - Updated data for the penalty
 * @param {string} token - User authentication token
 * @returns {Promise<Object>} A promise that resolves to the updated penalty data
 */
const updatePenalty = async (penaltyData, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const response = await axios.put(API_URL + penaltyData.penaltyId, penaltyData, config);
  
    return response.data;
}

/**
 * Exports the penalty service functions.
 */
const penaltyService = {
    createPenalty,
    getPenalties,
    getPenalty,
    deletePenalty,
    updatePenalty
}

export default penaltyService