import { React, useState } from 'react'
import {useDispatch} from "react-redux"
import {createPenalty} from "../../features/penalties/penaltySlice"
import { toast } from 'react-toastify';
import { SiAddthis } from "react-icons/si";
import PropTypes from 'prop-types';

/**
 * Component for adding a new penalty.
 * @module PenaltyFormAdd
 * @param {Function} closeModal - Function to close the modal after adding a penalty.
 * @returns {JSX.Element} PenaltyFormAdd component.
 */

function PenaltyFormAdd({closeModal}) {
  const [penaltyData, setPenaltyData] = useState({
    penaltyName: "",
    penaltyFee: "",
  })

  const {penaltyName, penaltyFee} = penaltyData
 
  const dispatch = useDispatch()

  /**
   * Handles form submission for adding a new penalty.
   * @param {Event} e - The form submission event.
   * @returns {void}
   */
  const onSubmit = (e) => {
    e.preventDefault()

    if(!penaltyName || !penaltyFee){
      toast.info("Enter all Penalty Infos before adding.")
    } else {

      dispatch(createPenalty({penaltyName, penaltyFee}))

      setPenaltyData({
        penaltyName: "",
        penaltyFee: "",
      });

      closeModal()

      toast.success("Penalty added!")
    }
  }

  /**
   * Handles input change for penalty information fields.
   * @param {Event} e - The input change event.
   * @returns {void}
   */
  const onChange = (e) => {
    setPenaltyData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <section className='form'>
      <form onSubmit={onSubmit}>        
        <div className="form-group">
          <label htmlFor='penaltyName'>Name</label>
          <input type="text" name="penaltyName" id="penaltyName" value={penaltyName} onChange={onChange} placeholder="Penalty Name" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='penaltyFee'>Fee</label>
          <input type="number" name="penaltyFee" id="penaltyFee" value={penaltyFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$"/>
        </div>
        <div className="form-group">
          <button className="btn btn-block-modal" type='submit'><SiAddthis className='icon add-icon' />Add Penalty</button>
        </div>
      </form>
    </section>
  )
}

PenaltyFormAdd.propTypes = {
  /**
   * Function to close the modal after adding a penalty.
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Function to handle form submission for adding a new penalty.
   */
  onSubmit: PropTypes.func,
  /**
   * Function to handle input change for penalty information fields.
   */
  onChange: PropTypes.func,
};

export default PenaltyFormAdd
