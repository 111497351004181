import React, { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import Spinner from "../../components/UI/Spinner"
import PlayerTable from "../../components/Players/PlayerTable"
import { getPlayers, resetPlayer } from '../../features/players/playerSlice'
import PlayerModalAdd from '../../components/Players/PlayerModalAdd';
import "./players.scss"

/**
 * Players component renders a page to manage players.
 * It displays a table of existing players and provides the functionality to add new players.
 * @component
 */

function Players() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Select necessary data from Redux store
  const {user} = useSelector((state) => state.auth)
  const {players, isLoading, isError, message} = useSelector((state) => state.players)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setEdit] = useState(null);

  // Function to open the modal for adding a new player
  const openModalAdd = () => {
    setIsModalOpen(true);
    setEdit(false);
  }
  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

  // useEffect to handle player state
  useEffect(() => {
    // Handle errors
    if(isError) {
      console.log(message)
    }

    // Redirect to login page if user is not authenticated
    if(!user) {
      navigate("/login")
    }

    // Dispatch action to fetch players
    dispatch(getPlayers())

    // Cleanup function to reset player state
    return () => {
      dispatch(resetPlayer())
    }
  }, [user, navigate, isError, message, dispatch])

  if(isLoading){
    return <Spinner/>
  }

  return (
    <div className='players-page'>
      <div className="manage-players">
        <div className="player-info">
          <h1>Manage Players</h1>
          <button onClick={openModalAdd}>Add New Player</button>
        </div>
        <PlayerTable players={players}/>
        <PlayerModalAdd isModalOpen={isModalOpen} onCloseModal={closeModal} players={players}/>  
      </div>
    </div>    
  )
}

export default Players
