import React, {useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import "./membershipTable.scss"
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {deleteMembership} from "../../features/memberships/membershipSlice"
import MembershipModalEdit from './MembershipModalEdit';
import { IoCheckmarkCircle, IoCloseCircle  } from "react-icons/io5";
import "../../styles/variables.scss"
import PropTypes from 'prop-types';

import { FiDownload } from "react-icons/fi"; // Import the download icon
import * as XLSX from 'xlsx'; // Import the xlsx library
import jsPDF from 'jspdf'; // Import jsPDF library
import 'jspdf-autotable'; // Import jsPDF Autotable plugin

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


/**
 * Component for displaying a table of membership information.
 * @module MembershipTable
 * @param {Object[]} memberships - An array containing membership objects.
 * @returns {JSX.Element} MembershipTable component.
 */

export default function MembershipTable({memberships}) {
  const dispatch = useDispatch()

  const [membershipEditData, setMembershipEditData] = useState({
    _id: "",
    membershipName: "",
    membershipStudentFee: "",
    membershipEmployeeFee: "",
    membershipStatus: ""
  })

  const [isModalOpen, setIsModalOpen] = useState(false);

    /**
   * Handles membership deletion.
   * @param {Object} membership - The membership object to be deleted.
   * @returns {void}
   */
  const handleDelete = (membership) => {
      if (window.confirm(`Are you sure you want to delete "${membership.membershipName}"?`)) {
        dispatch(deleteMembership(membership._id));
        toast.success(`Membership "${membership.membershipName}" deleted!`);
      } else {
        toast.info('Delete membership cancelled.');
      }
  };

    /**
   * Opens the edit modal for a membership.
   * @param {string} _id - The ID of the membership to edit.
   * @param {string} membershipName - The name of the membership to edit.
   * @param {string} membershipStudentFee - The student fee of the membership to edit.
   * @param {string} membershipEmployeeFee - The employee fee of the membership to edit.
   * @param {boolean} membershipStatus - The status of the membership to edit.
   * @returns {void}
   */
  const openModalEdit = (_id, membershipName, membershipStudentFee, membershipEmployeeFee, membershipStatus) => {
    setIsModalOpen(true);      
    setMembershipEditData({...membershipEditData, _id, membershipName, membershipStudentFee, membershipEmployeeFee, membershipStatus});
  }
  
  /**
   * Closes the edit modal.
   * @returns {void}
   */
  const closeModal = () => setIsModalOpen(false);

  /**
   * Gets the current date for naming the exported file.
   * @returns {string}
   */
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  /**
   * Handles exporting memberships data to different file formats.
   * @param {string} type - The type of file to export ('csv', 'excel', 'pdf').
   * @returns {void}
   */
  const handleExport = (type) => {
    const exportData = memberships.map(membership => ({
      "Membership Name": membership.membershipName,
      "Membership Student Fee": membership.membershipStudentFee,
      "Membership Employee Fee": membership.membershipEmployeeFee,
      "Membership Status": membership.membershipStatus ? "Active" : "Inactive",
    }));

    const currentDate = getCurrentDate();

    if (type === 'csv') {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);     
      link.download = `PenPro_Memberships_${currentDate}.csv`;
      link.click();
    } else if (type === 'excel') {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Memberships");      
      XLSX.writeFile(workbook, `PenPro_Memberships_${currentDate}.xlsx`);
    } else if (type === 'pdf') {
      const doc = new jsPDF();
      doc.autoTable({
        head: [['Membership Name', 'Membership Student Fee', 'Membership Employee Fee', 'Membership Status']],
        body: exportData.map(row => [
          row["Membership Name"],
          row["Membership Student Fee"],
          row["Membership Employee Fee"],
          row["Membership Status"]
        ]),
      });     
      doc.save(`PenPro_Memberships_${currentDate}.pdf`);
    }
  }

  // Table Column definitions
  const columns = [
      { 
        field: 'id',
        headerName: 'ID',
        width: 50,
      },
      { 
          field: 'penaltyName',
          headerName: 'Penalty Name',
          width: 300,
      },
      {
        field: 'membershipStudentFee',
        headerName: 'Student Fee',
        width: 90,
        editable: false,
      },
      {
        field: 'membershipEmployeeFee',
        headerName: 'Employee Fee',
        width: 110,
        editable: false,
      },
      {
        field: 'membershipStatus',
        headerName: 'Status',
        width: 90,
        editable: false,
        renderCell: (params) => {
          return (
            <div className='status-icon'>
              {params.row.membershipStatus === true ? <IoCheckmarkCircle className='checked-icon'/> : <IoCloseCircle className='not-checked-icon'/>}
            </div>
          )
        }
      },
      {
        field: 'action',
        headerName: 'Actions',
        width: 90,
        renderCell: (params) => {
            return (
                <div className='action-icons'>
                    <div className="membership-edit-icon" onClick={() => openModalEdit(params.row.action._id, params.row.action.membershipName, params.row.action.membershipStudentFee, params.row.action.membershipEmployeeFee, params.row.action.membershipStatus)} title='Edit Membership'><FaEdit/></div>
                    <div className="membership-delete-icon" onClick={() => handleDelete(params.row.action)} title='Delete Membership'><MdDeleteForever/></div>
                </div>
            )
        }
      },
  ];
    
  // Table row definition
  const rows = memberships.map((membership, index) => ({
      id: index + 1,
      penaltyName: membership.membershipName,
      membershipStudentFee: membership.membershipStudentFee,
      membershipEmployeeFee: membership.membershipEmployeeFee,
      membershipStatus: membership.membershipStatus,
      action: membership
  }));

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc', padding: '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/*<GridToolbarExport />*/}
          <FormControl sx={{ m: 1, width: 140, mt: 3 }}>
          <Select
              displayEmpty
              input={<OutlinedInput />}
              renderValue={() => 
                <p style={{ color: '#90caf9' }}><FiDownload style={{ marginRight: '4px' }} /> EXPORT</p>
              }
          >                              
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('csv')} title="Export to CSV" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> CSV
                </div>
              </MenuItem>
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('excel')} title="Export to Excel" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> EXCEL
                </div>
              </MenuItem>
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('pdf')} title="Export to PDF" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> PDF
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <GridToolbarQuickFilter />
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="membership-table">
        <Box>
            <DataGrid
                className='membership-grid'
                rows={rows}
                columns={columns}
                autoHeight={true}
                localeText={{ 
                  noRowsLabel: "No Memberships detected: Add Memberships!" 
                }}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 5,
                    },
                },
                }}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                sx={{
                  boxShadow: 2,
                  border: 2,
                  '& .MuiDataGrid-cell:hover': {
                    color: "cadetblue",                    
                  },                  
                }}
            />
        </Box>
        <MembershipModalEdit isModalOpen={isModalOpen} onCloseModal={closeModal} membershipEditData={membershipEditData}/>
    </div>
  );
}

MembershipTable.propTypes = {
  /**
   * An array containing membership objects.
   */
  memberships: PropTypes.array.isRequired,
  /**
   * Function to handle membership deletion.
   */
  handleDelete: PropTypes.func,
  /**
   * Function to open the edit modal for a membership.
   */
  openModalEdit: PropTypes.func,
  /**
   * Function to close the edit modal.
   */
  closeModal: PropTypes.func,
  /**
   * Function to retrieve the current date.
   */
  getCurrentDate: PropTypes.func,
  /**
   * Function to handle the export of the table content as CSV, EXCEL and PDF.
   */
  handleExport: PropTypes.func,
};