import React from 'react'
import { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import Spinner from "../../components/UI/Spinner"
import { getPenalties, resetPenalty } from '../../features/penalties/penaltySlice'
import PenaltyModalAdd from '../../components/Penalties/PenaltyModalAdd';
import PenaltyTable from '../../components/Penalties/PenaltyTable'
import "./penalties.scss"

/**
 * Penalties component renders a page to manage penalties.
 * It displays a table of existing penalties and provides the functionality to add new penalties.
 * @component
 */

function Penalties() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Select necessary data from Redux store
  const {user} = useSelector((state) => state.auth)
  const {penalties, isLoading, isError, message} = useSelector((state) => state.penalties)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setEdit] = useState(null);

  // Function to open the modal for adding a new penalty
  const openModalAdd = () => {
    setIsModalOpen(true);
    setEdit(false);
  }
  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

  // useEffect to handle penalty state
  useEffect(() => {
    // Handle errors
    if(isError) {
      console.log(message)
    }

     // Redirect to login page if user is not authenticated
    if(!user) {
      navigate("/login")
    }

    // Dispatch action to fetch penalties
    dispatch(getPenalties())

     // Cleanup function to reset penalty state
    return () => {
      dispatch(resetPenalty())
    }
  }, [user, navigate, isError, message, dispatch])

  if(isLoading){
    return <Spinner/>
  }

  return (
    <div className='penalties-page'>
      <div className="manage-penalties">
        <div className="penalties-info">
          <h1>Manage Penalties</h1>
          <button onClick={openModalAdd}>Add New Penalty</button>
        </div>
        <PenaltyTable penalties={penalties} onCloseModal={closeModal}/>
        <PenaltyModalAdd isModalOpen={isModalOpen} onCloseModal={closeModal} penalties={penalties}/>  
      </div>
    </div>
  )
}

export default Penalties
