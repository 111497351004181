import React from 'react'
import { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import Spinner from "../../components/UI/Spinner"
import { getPlayers, resetPlayer } from '../../features/players/playerSlice'
import { getPenalties, resetPenalty } from '../../features/penalties/penaltySlice'
import { getMemberships, resetMembership } from '../../features/memberships/membershipSlice'
import MembershipModalEdit from '../../components/Memberships/MembershipModalEdit'
import TopFees from "../../components/DashboardBox/TopFees/TopFees"
import {Link} from "react-router-dom"
import { MdDashboardCustomize } from "react-icons/md";
import { IoShirtSharp } from "react-icons/io5";
import { FaSackDollar, FaClipboardList } from "react-icons/fa6";
import Statistics from '../../components/DashboardBox/Statistics/Statistics'
import Assignment from '../../components/DashboardBox/Assignment/Assignment'
import BoxPieChart from '../../components/DashboardBox/BoxPieChart/BoxPieChart'
import "./dashboard.scss"

/**
 * Dashboard component displays a summary of various records and statistics.
 * @component
 */

function Dashboard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Selectors to access Redux store state
  const {user} = useSelector((state) => state.auth)
  const {players, isErrorPlayer, messagePlayer} = useSelector((state) => state.players)
  const isLoadingPlayer = useSelector((state) => state.players.isLoading)
  const {penalties, isErrorPenalty, messagePenalty} = useSelector((state) => state.penalties) 
  const isLoadingPenalty = useSelector((state) => state.penalties.isLoading)
  const {memberships, isErrorMembership, messageMembership} = useSelector((state) => state.memberships) 
  const isLoadingMembership = useSelector((state) => state.memberships.isLoading)
  
   // State variables to store various counts
  const [totalFees, setTotalFees] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalPenalties, setTotalPenalties] = useState(0);
  const [totalMemberships, setTotalMemberships] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  // State variable to control the visibility of the membership modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // useEffect to handle Dashboard items
  useEffect(() => {
    // Check for error messages and log them if present
    if(isErrorPlayer) {
      console.log(messagePlayer)
    }

    if(isErrorPenalty) {
      console.log(messagePenalty)
    }

    if(isErrorMembership) {
      console.log(messageMembership)
    }

    // Redirect to login page if user is not authenticated
    if(!user) {
      navigate("/login")
    }

    // Fetch data for players, penalties, and memberships
    dispatch(getPlayers())
    dispatch(getPenalties())
    dispatch(getMemberships())

     // Cleanup function to reset Redux state when unmounting
    return () => {
      dispatch(resetPlayer())
      dispatch(resetPenalty())
      dispatch(resetMembership())
    }
  }, [user, navigate, isErrorPlayer, isErrorMembership, isErrorPenalty, messagePenalty, messageMembership, messagePlayer, dispatch])

  // useEffect to handle dashboard statistics
  useEffect(() => {
    // Count the number of players
    setTotalPlayers(players.length);
    // Count the number of penalties
    setTotalPenalties(penalties.length);
    // Count the number of memberships
    setTotalMemberships(memberships.length);
    // Sum the total number of records
    setTotalRecords(players.length + penalties.length + memberships.length);
  }, [players, penalties, memberships, totalRecords]);

  if(isLoadingPlayer || isLoadingPenalty || isLoadingMembership){
    return <Spinner/>
  }
  
  return (
    <div className='dashboard-page'>
      <div className='content-heading'>
        <h1>Dashboard</h1>
      </div>
      <div className='dashboard'>
        <div className="dashboard-box box1">
          <TopFees players={players}/>
        </div>
        <div className="dashboard-box box2">
          <Statistics iconType={<MdDashboardCustomize />} recordType="Total Records" valueRecords={totalRecords} linkTo="/dashboard"/>
        </div>
        <div className="dashboard-box box3">
          <Statistics iconType={<IoShirtSharp />} recordType="Player Records" valueRecords={totalPlayers} linkTo="/players"/>
        </div>
        <div className="dashboard-box box4">
          <BoxPieChart totalPlayers={totalPlayers} totalPenalties={totalPenalties} totalMemberships={totalMemberships}/>
        </div>
        <div className="dashboard-box box5">
          <Statistics iconType={<FaSackDollar />} recordType="Penalty Records" valueRecords={totalPenalties} linkTo="/penalties"/>                
        </div>
        <div className="dashboard-box box6">
          <Statistics iconType={<FaClipboardList />} recordType="Membership Records" valueRecords={totalMemberships} linkTo="/memberships"/>
        </div>
        <div className="dashboard-box box7">
          <Assignment players={players} penalties={penalties}/>
        </div>
      </div>    
      <MembershipModalEdit isModalOpen={isModalOpen} onCloseModal={closeModal}/>
    </div>
  )
}

export default Dashboard
