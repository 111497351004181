import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import membershipService from "./membershipService"

/**
 * Initial state for the membership slice.
 * @type {Object}
 */
const initialState = {
    memberships: [],
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message: ""
}

/**
 * Thunk to create a new membership.
 * @async
 * @function createMembership
 * @param {Object} membershipData - Data for creating the membership
 * @returns {Promise<Object>} A promise that resolves to the created membership data
 */
export const createMembership = createAsyncThunk("memberships/create", async (membershipData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await membershipService.createMembership(membershipData, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to retrieve user memberships.
 * @async
 * @function getMemberships
 * @returns {Promise<Object>} A promise that resolves to the user's memberships data
 */
export const getMemberships = createAsyncThunk("memberships/getMemberships", async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await membershipService.getMemberships(token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to delete a membership.
 * @async
 * @function deleteMembership
 * @param {string} id - ID of the membership to delete
 * @returns {Promise<Object>} A promise that resolves to the deleted membership data
 */
export const deleteMembership = createAsyncThunk("memberships/delete", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await membershipService.deleteMembership(id, token)
    } catch (err) {
        const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Thunk to update a membership.
 * @async
 * @function updateMembership
 * @param {Object} membershipData - Updated data for the membership
 * @returns {Promise<Object>} A promise that resolves to the updated membership data
 */
export const updateMembership = createAsyncThunk('memberships/update', async (membershipData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      // Make API call to update membership
      const updatedMembership = await membershipService.updateMembership(membershipData, token);
      return updatedMembership;
    } catch (err) {
      const message = (err.response && err.response.data && err.response.data.message) || err.message || err.toString()
      return thunkAPI.rejectWithValue(message)
    }
})

/**
 * Slice for managing memberships state.
 * @type {Object}
 */
export const membershipSlice = createSlice({
    name: "membership",
    initialState,
    reducers: {
        resetMembership: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMembership.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createMembership.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.memberships.push(action.payload)
            })
            .addCase(createMembership.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getMemberships.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMemberships.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.memberships = action.payload
            })
            .addCase(getMemberships.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteMembership.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteMembership.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.memberships = state.memberships.filter((membership) => membership._id !== action.payload.id)
            })
            .addCase(deleteMembership.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            }) 
            .addCase(updateMembership.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateMembership.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.memberships = state.memberships.map(membership => membership._id === action.payload._id ? action.payload : membership);
            })
            .addCase(updateMembership.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })                 
    }
})


/**
 * Exports action creators and reducer for the membership slice.
 */
export const {resetMembership} = membershipSlice.actions
export default membershipSlice.reducer