import { React, useState } from 'react'
import {useDispatch} from "react-redux"
import {createMembership} from "../../features/memberships/membershipSlice"
import { toast } from 'react-toastify';
import { RiPlayListAddFill  } from "react-icons/ri";
import PropTypes from 'prop-types';

/**
 * Component for adding a membership.
 * @module MembershipFormAdd
 * @param {Function} closeModal - Function to close the modal.
 * @returns {JSX.Element} MembershipFormAdd component.
 */

function MembershipFormAdd({closeModal}) {
  const [membershipData, setMembershipData] = useState({
    membershipName: "",
    membershipStudentFee: "",
    membershipEmployeeFee: "",
  })

  const {membershipName, membershipStudentFee, membershipEmployeeFee} = membershipData
 
  const dispatch = useDispatch()

   /**
   * Handles form submission to create a new membership.
   * @param {Event} e - Form submission event.
   * @returns {void}
   */
  const onSubmit = (e) => {
    e.preventDefault()
    if(!membershipName || !membershipStudentFee || !membershipEmployeeFee){
      toast.info("Enter all Membership Infos before adding.")
    } else {
      dispatch(createMembership({membershipName, membershipStudentFee, membershipEmployeeFee}))

      setMembershipData({
          membershipName: "",
          membershipStudentFee: "",
          membershipEmployeeFee: "",
      });

      closeModal()

      toast.success("Membership added!")
    }
  }

  /**
   * Handles input change in the form fields.
   * @param {Event} e - Input change event.
   * @returns {void}
   */
  const onChange = (e) => {
    setMembershipData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <section className='form'>
      <form onSubmit={onSubmit}>        
        <div className="form-group">
          <label htmlFor='membershipName'>Membership Name</label>
          <input type="text" name="membershipName" id="membershipName" value={membershipName} onChange={onChange} placeholder="Membership Name" />
        </div>
        <div className="form-group">
          <label htmlFor='membershipStudentFee'>Student Fee</label>
          <input type="number" name="membershipStudentFee" id="membershipStudentFee" value={membershipStudentFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$"/>
        </div>
        <div className="form-group">
          <label htmlFor='membershipEmployeeFee'>Employee Fee</label>
          <input type="number" name="membershipEmployeeFee" id="membershipEmployeeFee" value={membershipEmployeeFee} onChange={onChange} placeholder="0.00" min="0" step=".01" pattern="^\d*(\.\d{0,2})?$"/>
        </div>
        <div className="form-group">
          <button className="btn btn-block-modal" type='submit'><RiPlayListAddFill  className='icon add-icon' /> Add Membership</button>
        </div>
      </form>
    </section>
  )
}

MembershipFormAdd.propTypes = {
  /**
   * Function to close the modal.
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Function to handle form submission.
   */
  onSubmit: PropTypes.func,
  /**
   * Function to handle input change.
   */
  onChange: PropTypes.func,
};

export default MembershipFormAdd
