import React, {useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid';
import { FaEdit } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import "./playerTable.scss"
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {deletePlayer, updatePlayer} from "../../features/players/playerSlice"
import PlayerModalEdit from './PlayerModalEdit';
import { HiOfficeBuilding } from "react-icons/hi";
import { IoSchool } from "react-icons/io5";
import PropTypes from 'prop-types';

import { FiDownload } from "react-icons/fi"; // Import the download icon
import * as XLSX from 'xlsx'; // Import the xlsx library
import jsPDF from 'jspdf'; // Import jsPDF library
import 'jspdf-autotable'; // Import jsPDF Autotable plugin

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

/**
 * Component for displaying a table of player information.
 * @module PlayerTable
 * @param {Object[]} players - An array containing player objects.
 * @returns {JSX.Element} PlayerTable component.
 */

export default function PlayerTable({players}) {
    const dispatch = useDispatch()

    const [playerEditData, setPlayerEditData] = useState({
      _id: "",
      number: "",
      playerSurname: "",
      playerName: "",
      playerPhone: "",
      playerFee: "",
      playerStudentStatus: "",
      playerFeeHistory: "",
    })

    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Handles player deletion.
     * @param {Object} player - The player object to be deleted.
     * @returns {void}
     */
    const handleDelete = (player) => {
        if (window.confirm(`Are you sure you want to delete "${player.playerName}"?`)) {
          dispatch(deletePlayer(player._id));
          toast.success(`Player "${player.playerName}" deleted!`);
        } else {
          toast.info('Delete player cancelled.');
        }
    };

    /**
     * Opens the edit modal for a player.
     * @param {string} _id - The ID of the player to edit.
     * @param {string} number - The number of the player to edit.
     * @param {string} playerSurname - The surname of the player to edit.
     * @param {string} playerName - The name of the player to edit.
     * @param {string} playerPhone - The phone number of the player to edit.
     * @param {string} playerFee - The fee of the player to edit.
     * @param {boolean} playerStudentStatus - The student status of the player to edit.
     * @returns {void}
     */
    const openModalEdit = (_id, number, playerSurname, playerName, playerPhone, playerFee, playerStudentStatus) => {
      setIsModalOpen(true);
      setPlayerEditData({...playerEditData, _id, number, playerSurname, playerName, playerPhone, playerFee, playerStudentStatus});
    }

    /**
     * Closes the edit modal.
     * @returns {void}
     */
    const closeModal = () => setIsModalOpen(false);

     /**
     * Handles the removal of a player's fee.
     * @param {Object} player - The player object whose fee is to be removed.
     * @returns {void}
     */
    const handleRemoveFee = (player) =>{
      
      if(player.playerFee === 0){
        toast.info("Fee already removed!")
      } else {
        if (window.confirm(`Are you sure you want to remove the fee from "${player.playerName}"?`)) {
          
          const updatedFee = 0
          const updateFeeHistory = []
    
          const updatedPlayerData = {
              playerId: player._id,
              number: player.number,
              playerSurname: player.playerSurname,
              playerName: player.playerName,
              playerPhone: player.playerPhone,
              playerFee: updatedFee,
              playerStudentStatus: player.playerStudentStatus,
              playerFeeHistory: updateFeeHistory
          };
    
          dispatch(updatePlayer(updatedPlayerData));
    
          toast.success(`Fee successfully removed from "${player.number} - ${player.playerSurname}, ${player.playerName}"!`)
        
        } else {
          toast.info('Remove player fee cancelled.');
        }
      }
    }

    /**
     * Gets the current month and year for naming the Excel file.
     * @returns {string}
     */
    const getCurrentDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    /**
   * Handles exporting players data to different file formats.
   * @param {string} type - The type of file to export ('csv', 'excel', 'pdf').
   * @returns {void}
   */
  const handleExport = (type) => {
    let separationCharacter;
    if(type === 'csv'){
      separationCharacter = ", "
    } else {
      separationCharacter = "; "
    }

    const exportData = players.map(player => ({
      "Player Number": player.number,
      "Player Surname": player.playerSurname,
      "Player Name": player.playerName,
      "Player Phone": player.playerPhone,
      "Player Fee": player.playerFee,
      "Player Fee History": Array.isArray(player.playerFeeHistory) ? player.playerFeeHistory.join(separationCharacter) : player.playerFeeHistory,
      "Player Student Status": player.playerStudentStatus ? "Student" : "Employee",
    }));

    const currentDate = getCurrentDate();

    if (type === 'csv') {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `PenPro_Players_${currentDate}.csv`;
      link.click();
    } else if (type === 'excel') {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Players");
      XLSX.writeFile(workbook, `PenPro_Players_${currentDate}.xlsx`);
    } else if (type === 'pdf') {
      const doc = new jsPDF();
      doc.autoTable({
        head: [['Player Number', 'Player Surname', 'Player Name', 'Player Phone', 'Player Fee', 'Player Fee History', 'Player Student Status']],
        body: exportData.map(row => [
          row["Player Number"],
          row["Player Surname"],
          row["Player Name"],
          row["Player Phone"],
          row["Player Fee"],
          row["Player Fee History"],
          row["Player Student Status"]
        ]),
      });
      doc.save(`PenPro_Players_${currentDate}.pdf`);
    }
  }

    // Table Column definitions
    const columns = [
        { 
          field: 'id',
          headerName: 'Number',
          type: 'number',
          width: 70,
        },
        {
          field: 'playerSurname',
          headerName: 'Surname',
          width: 150,
          editable: false,
        },
        {
          field: 'playerName',
          headerName: 'First Name',
          width: 150,
          editable: false,
        },
        {
          field: 'playerPhone',
          headerName: 'Phone',
          type: 'number',
          width: 165,
          editable: false,
        },
        {
          field: 'playerFee',
          headerName: 'Fee',
          type: 'number',
          width: 90,
          editable: false,
        },
        {
          field: 'playerFeeHistory',
          headerName: 'Fee History',
          width: 165,
          editable: false,
        },
        {
          field: 'playerStudentStatus',
          headerName: 'Status',
          type: 'boolean',
          width: 90,
          editable: false,
          renderCell: (params) => {
            return (
              <div className='status-icon'>
                {params.row.playerStudentStatus === true ? <IoSchool className='student-icon' title='Student Status'/> : <HiOfficeBuilding className='office-icon' title='Employee Status'/>}
              </div>
            )
          }
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 130,
            renderCell: (params) => {
                return (
                    <div className='action-icons'>
                        <div className="player-edit-icon" onClick={() => openModalEdit(params.row.action._id, params.row.action.number, params.row.action.playerSurname, params.row.action.playerName, params.row.action.playerPhone, params.row.action.playerFee, params.row.action.playerStudentStatus)} title='Edit Player'><FaEdit/></div>
                        <div className="player-rmFee-icon" onClick={() => handleRemoveFee(params.row.action)} title='Remove Fee'><FaMoneyBillTransfer/></div>
                        <div className="player-delete-icon"  onClick={() => handleDelete(params.row.action)} title='Delete Player'><MdDeleteForever/></div>
                    </div>
                )
            }
        },
    ];
    
    // Table row definition 
    const rows = players.map(player => ({
        id: player.number,
        playerSurname: player.playerSurname,
        playerName: player.playerName,
        playerPhone: player.playerPhone,
        playerFee: player.playerFee,
        playerFeeHistory: player.playerFeeHistory,
        playerStudentStatus: player.playerStudentStatus, 
        action: player      
    }));

    const CustomToolbar = () => {
      return (
        <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc', padding: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/*<GridToolbarExport />*/}
            <FormControl sx={{ m: 1, width: 140, mt: 3 }}>
              <Select
                  displayEmpty
                  input={<OutlinedInput />}
                  renderValue={() => 
                    <p style={{ color: '#90caf9' }}><FiDownload style={{ marginRight: '4px' }} /> EXPORT</p>
                  }
              >                              
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('csv')} title="Export to CSV" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> CSV
                </div>
              </MenuItem>
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('excel')} title="Export to Excel" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> EXCEL
                </div>
              </MenuItem>
              <MenuItem>
                <div className="export-icon" onClick={() => handleExport('pdf')} title="Export to PDF" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '14.5px', color: '#90caf9', marginLeft: '8px' }}>
                  <FiDownload style={{ marginRight: '4px' }} /> PDF
                </div>
              </MenuItem>
            </Select>
          </FormControl>
          </div>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <GridToolbarQuickFilter />
          </div>
        </GridToolbarContainer>
      );
    }

  return (
    <div className="player-table">
        <Box>
            <DataGrid
                className='player-grid'
                rows={rows}
                columns={columns}
                autoHeight={true}
                localeText={{ 
                  noRowsLabel: "No Players detected: Add Players!" 
                }}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 5,
                    },
                },
                }}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                sx={{
                  boxShadow: 2,
                  border: 2,
                  '& .MuiDataGrid-cell:hover': {
                    color: "cadetblue",                    
                  },                  
                }}
            />
        </Box>
        <PlayerModalEdit isModalOpen={isModalOpen} onCloseModal={closeModal} playerEditData={playerEditData} players={players}/>
    </div>
    
  );
}

PlayerTable.propTypes = {
  /**
     * An array containing player objects.
     */
  players: PropTypes.array.isRequired,
  /**
   * Function to handle player deletion.
   */
  handleDelete: PropTypes.func,
  /**
   * Function to open the edit modal for a player.
   */
  openModalEdit: PropTypes.func,
  /**
   * Function to close the edit modal.
   */
  closeModal: PropTypes.func,
  /**
   * Function to handle the removal of a player's fee.
   */
  handleRemoveFee: PropTypes.func,
  /**
   * Function to retrieve the current month and year.
   */
  getCurrentDate: PropTypes.func,
  /**
   * Function to handle the export of the table content as CSV, EXCEL and PDF.
   */
  handleExport: PropTypes.func,
};