import React from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import PlayerFormAdd from "./PlayerFormAdd"
import PropTypes from 'prop-types';

/**
 * Component for displaying a modal to add a new player.
 * @module PlayerModalAdd
 * @param {boolean} isModalOpen - Indicates whether the modal is open or not.
 * @param {Function} onCloseModal - Function to close the modal.
 * @param {Object[]} players - An array containing existing player objects.
 * @returns {JSX.Element} PlayerModalAdd component.
 */

function PlayerModalAdd({isModalOpen, onCloseModal, players}) {
  return (
    <div>
      <Modal open={isModalOpen} onClose={onCloseModal} center classNames={{overlay: 'customOverlay', modal: 'customModal', closeButton: "closeBtn"}}>
        <h2>Add Player</h2>
        <PlayerFormAdd players={players} closeModal={onCloseModal}/>
      </Modal>
    </div>
  )
}

PlayerModalAdd.propTypes = {
  /**
   * Indicates whether the modal is open or not.
   */
  isModalOpen: PropTypes.bool.isRequired,
  /**
   * Function to close the modal.
   */
  onCloseModal: PropTypes.func.isRequired,
  /**
   * An array containing existing player objects.
   */
  players: PropTypes.array.isRequired,
};

export default PlayerModalAdd
