import React from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import PlayerFormEdit from "./PlayerFormEdit"
import PropTypes from 'prop-types';

/**
 * Component for displaying a modal to edit player information.
 * @module PlayerModalEdit
 * @param {boolean} isModalOpen - Indicates whether the modal is open or not.
 * @param {Function} onCloseModal - Function to close the modal.
 * @param {Object} playerEditData - Data of the player being edited.
 * @param {Object[]} players - An array containing existing player objects.
 * @returns {JSX.Element} PlayerModalEdit component.
 */

function PlayerModalEdit({isModalOpen, onCloseModal, playerEditData, players}) {
  return (
    <div>
      <Modal open={isModalOpen} onClose={onCloseModal} center classNames={{overlay: 'customOverlay', modal: 'customModal', closeButton: "closeBtn"}}>
        <h2>Edit Player</h2>
        <PlayerFormEdit players={players} playerEditData={playerEditData}/>
      </Modal>
    </div>
  )
}

PlayerModalEdit.propTypes = {
  /**
   * Indicates whether the modal is open or not.
   */
  isModalOpen: PropTypes.bool.isRequired,
  /**
   * Function to close the modal.
   */
  onCloseModal: PropTypes.func.isRequired,
  /**
   * Data of the player being edited.
   */
  playerEditData: PropTypes.object.isRequired,
  /**
   * An array containing existing player objects.
   */
  players: PropTypes.array.isRequired,
};

export default PlayerModalEdit
