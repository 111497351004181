import React from 'react'

/**
 * Spinner component displays a loading spinner.
 * @component Spinner
 * @returns {JSX.Element} Returns the JSX for the Spinner component.
 */
function Spinner() {
  return (
    <div className='loading-spinner-container'>
      <div className="loading-spinner">
        
      </div>
    </div>
  )
}

export default Spinner
