import React, { useState }  from 'react'
import {FaSignInAlt, FaUser } from "react-icons/fa"
import {Link, useNavigate} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { ImMenu } from "react-icons/im";
import { RiUserAddFill } from "react-icons/ri";
import { IoHome } from "react-icons/io5";
import MenuModal from '../../MenuModal/MenuModal'
import "./header.scss"
import PropTypes from 'prop-types';

/**
 * Header component for the application.
 * @component Header
 * @returns {JSX.Element} Header component JSX
 */

function Header() {
  const {user} = useSelector((state) => state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Function to open the menu modal.
   * @function
   * @name showModal
   */
  const showModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Function to close the menu modal.
   * @function
   * @name closeModal
   */
  const closeModal = () => {
    setIsModalOpen(false);
  };

return (
    <header className="header">
        <div className="logo-header">
            <Link className="header-img" to="/">
                <img src="img/logo512.png" width="40" height="40" alt="PenPro Logo" />
            </Link>
            <Link to="/">PenPro</Link>
        </div>
        <div className="icons">
          <div className="user">
             {user && <><FaUser /> {user.name}</>}
          </div>
          {!user &&
            <div className="menu-modal-nav">               
              <Link to="/" className="menu-link">
                <IoHome/> <span>Home</span>
              </Link>
              <Link to="/login" className="menu-link login-btn">
                <FaSignInAlt/> <span>Login</span>
              </Link>
              <Link to="/register" className="menu-link">
                <RiUserAddFill /> <span>Register</span>
              </Link>                                                                      
            </div>
          } 
          <div className="menu-modal">
            <ImMenu className="menu-icon" onClick={showModal} />
            <MenuModal isModalOpen={isModalOpen} onCloseModal={closeModal} />  
          </div>
        </div>
      </header>
  )
}

Header.propTypes = {
  /**
   * Function to open the menu modal.
   */
  showModal: PropTypes.func,
  /**
   * Function to close the menu modal.
   */
  closeModal: PropTypes.func,
};

export default Header
