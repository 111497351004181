import React from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {Link, useNavigate} from "react-router-dom"
import { MdDashboardCustomize } from "react-icons/md";
import { FaSignInAlt, FaSignOutAlt } from "react-icons/fa"
import { IoShirtSharp } from "react-icons/io5";
import { FaSackDollar, FaClipboardList } from "react-icons/fa6";
import {logout, reset} from "../../features/auth/authSlice"
import { useSelector, useDispatch } from 'react-redux';
import { RiUserAddFill } from "react-icons/ri";
import { IoHome } from "react-icons/io5";
import "./menuModal.scss"
import PropTypes from "prop-types";

/**
 * Menu Modal Component:
 * This component provides a modal menu for navigation within the application.
 * @component MenuModal
 * @param {boolean} props.isModalOpen - Whether the modal is open or not.
 * @param {function} props.onCloseModal - Function to close the modal.
 * @returns {JSX.Element} MenuModal component JSX
 */

function MenuModal({ isModalOpen, onCloseModal }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {user} = useSelector((state) => state.auth)

     /**
     * Handles the logout functionality.
     * Dispatches logout and reset actions and navigates to the home page ("/").
     * @function
     * @name onLogout
     */
    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate("/")
    }

    /**
     * Handles the click event of menu items.
     * Closes the modal and executes the click event.
     * @function
     * @name handleItemClick
     */
    const handleItemClick = () => {
        onCloseModal(); 
    };

  return (
    <div>
      <Modal open={isModalOpen} onClose={onCloseModal} center classNames={{overlay: 'customOverlay', modal: 'customMenuModal', closeButton: "closeBtn"}}>
        <h2>Menu</h2>
        {user ? <>
            <div className="menu-modal">
                <div className="menu-item-modal">
                    <span className="menu-title-modal">MAIN</span>
                    <Link to="/dashboard" className="menu-link-modal" onClick={handleItemClick}>
                        <MdDashboardCustomize /> <span>Dashboard</span>
                    </Link>
                </div>
                <div className="menu-item-modal">
                    <span className="menu-title-modal">LISTS</span>
                    <Link to="/players" className="menu-link-modal" onClick={handleItemClick}>
                        <IoShirtSharp/> <span>Players</span>
                    </Link>
                    <Link to="/penalties" className="menu-link-modal" onClick={handleItemClick}>
                        <FaSackDollar/> <span>Penalties</span>
                    </Link>
                    <Link to="/memberships" className="menu-link-modal" onClick={handleItemClick}>
                        <FaClipboardList/> <span>Memberships</span>
                    </Link>
                </div>
                <div className="menu-item-modal">
                    <span className="menu-title-modal">OTHER</span>
                    <Link to="/" className="menu-link-modal" onClick={() => { handleItemClick(); onLogout(); }} >
                    <FaSignOutAlt />
                        <span>Logout</span>
                    </Link>             
                </div>
            </div>
        </> : <>
            <div className="menu-modal">
                <div className="menu-item-modal">                    
                    <Link to="/" className="menu-link-modal" onClick={handleItemClick}>
                        <IoHome /> <span>Home</span>
                    </Link>
                    <Link to="/login" className="menu-link-modal" onClick={handleItemClick}>
                        <FaSignInAlt/> <span>Login</span>
                    </Link>
                    <Link to="/register" className="menu-link-modal" onClick={handleItemClick}>
                        <RiUserAddFill /> <span>Register</span>
                    </Link>       
                </div>
            </div>           
        </>}
      </Modal>
    </div>
  )
}


MenuModal.propTypes = {
    /**
     * Whether the modal is open or not.
     */
    isModalOpen: PropTypes.bool.isRequired,
    /**
     * Function to close the modal.
     */
    onCloseModal: PropTypes.func.isRequired,
    /**
     * Function to handle logout.
     */
    onLogout: PropTypes.func,
    /**
     * Function to handle menu item click.
     */
    handleItemClick: PropTypes.func,
};

export default MenuModal