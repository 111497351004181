import { Link } from 'react-router-dom';
import "./statistics.scss"
import PropTypes from 'prop-types';

/**
 * Component for displaying statistics.
 * @component Statistics
 * @param {JSX.Element} props.iconType - Icon element for the statistics.
 * @param {string} props.recordType - Type of record (e.g., "Players", "Penalties").
 * @param {number} props.valueRecords - Total number of records.
 * @param {string} props.linkTo - Link to navigate when clicking on "Add new records" or "View all".
 * @returns {JSX.Element} Statistics component JSX
 */

function Statistics({iconType, recordType, valueRecords, linkTo}) {
  return (
    <div className='box-statistic'>
      <div className="box-info">
        <div className="box-title">
          {iconType}
          <span>{recordType}</span>
        </div>
        <h1>{valueRecords}</h1>
        <p className='box-link'>{recordType === "Total Records" || valueRecords === 0 ? <Link to={`${linkTo}`}>Add new records</Link> : <Link to={`${linkTo}`}>View all</Link>}</p>
      </div>
    </div>
  )
}

Statistics.propTypes = {
  /**
   * Icon element for the statistics.
   */
  iconType: PropTypes.element.isRequired,
  /**
   * Type of record (e.g., "Players", "Penalties").
   */
  recordType: PropTypes.string.isRequired,
  /**
   * Total number of records.
   */
  valueRecords: PropTypes.number.isRequired,
  /**
   * Link to navigate when clicking on "Add new records" or "View all".
   */
  linkTo: PropTypes.string.isRequired,
};

export default Statistics;