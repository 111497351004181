import { React, useState } from 'react'
import {useDispatch} from "react-redux"
import {createPlayer} from "../../features/players/playerSlice"
import { toast } from 'react-toastify';
import { RiUserAddFill } from "react-icons/ri";
import PropTypes from 'prop-types';

/**
 * Component for adding a new player.
 * @module PlayerFormAdd
 * @prop {Object[]} players - An array containing existing player objects.
 * @prop {Function} closeModal - Function to close the modal after adding a player.
 * @returns {JSX.Element} PlayerFormAdd component.
 */

function PlayerFormAdd({players, closeModal}) {
  const [playerData, setPlayerData] = useState({
    number: "",
    playerSurname: "",
    playerName: "",
    playerPhone: "",
    playerStudentStatus: false,
  })

  const {number, playerSurname, playerName, playerPhone, playerStudentStatus} = playerData
 
  const dispatch = useDispatch()
  
   /**
   * Handles form submission.
   * @function
   * @name onSubmit
   * @param {Event} e
   * @returns {void}
   */
  const onSubmit = async (e) => {
    e.preventDefault()

    if(!number || !playerSurname || !playerName || !playerPhone){
      toast.info("Enter all Player Infos before adding.")
    } else {
      // Check if the number already exists
      const isNumberExists = players.some((player) => player.number === parseInt(number));

      if (isNumberExists) {
        toast.info('Number already exists! Please choose a different Number.');
      } else {
        dispatch(createPlayer({ number, playerSurname, playerName, playerPhone, playerStudentStatus }));

        setPlayerData({
          number: '',
          playerSurname: '',
          playerName: '',
          playerPhone: '',
        });

        closeModal()

        toast.success('Player added!');
      }
    }
  }

  /**
   * Handles input change.
   * @function
   * @name onChange
   * @param {Event} e
   * @returns {void}
   * @public
   */
  const onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setPlayerData((prevState) => ({
      ...prevState,
      [e.target.name]: value
    }))
  }

  return (
    <div className='form'>
      <form onSubmit={onSubmit}>        
        <div className="form-group">
          <label htmlFor='number'>Number</label>
          <input type="number" min="0" max="99" name="number" id="number" value={number} onChange={onChange} placeholder="Players Number"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerSurname'>Surname</label>
          <input type="text" name="playerSurname" id="playerSurname" value={playerSurname} onChange={onChange} placeholder="Players Surname" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerName'>Name</label>
          <input type="text" name="playerName" id="playerName" value={playerName} onChange={onChange} placeholder="Players Name" maxLength="50"/>
        </div>
        <div className="form-group">
          <label htmlFor='playerPhone'>Phone</label>
          <input type="tel" name="playerPhone" id="playerPhone" value={playerPhone} onChange={onChange} placeholder="Enter Phone Number (Format: +49123123456)" pattern="^\+49[1-9][0-9]{1,14}$"/>
        </div>
        <div className='form-group-checkbox-container'>
          <label htmlFor='Status'>Status</label>        
          <div className="form-group-checkbox"> 
            <input type="checkbox" name="playerStudentStatus" id="playerStudentStatus" className='toggle-checkbox' checked={playerStudentStatus} onChange={onChange} />
            <label htmlFor="playerStudentStatus">{playerStudentStatus ? 'Student' : 'Employee'}</label>
          </div>
        </div>
        <div className="form-group">
          <button className="btn btn-block-modal" type='submit'><RiUserAddFill className='icon add-icon' /> Add Player</button>
        </div>
      </form>
    </div>
  )
}

PlayerFormAdd.propTypes = {
  /**
   * An array containing existing player objects.
   */
  players: PropTypes.array.isRequired,
  /**
   * Function to close the modal after adding a player.
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Handles input change.
   */
  onChange: PropTypes.func,
  /**
   * Handles form submit.
   */
  onSubmit: PropTypes.func,
}; 

export default PlayerFormAdd